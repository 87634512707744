
@import "../../assets/scss/index.scss";

.footer{
    background: var(--lightgray);
    padding: 80px 0 20px 0; 
    
    @include tab-md() {
        padding: 100px 0 20px 0; 
    }
    
}

.getConect{
    margin-bottom: 60px;
    text-align: center;
    p{
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
        color: var(--black);
        margin-bottom: 10px;
        @include tab-md() {
            font-size: 27px;
            line-height: 40px;
        }
    }
    h3{
        font-weight: 500;
        font-size: 40px;
        line-height: 52px;
        color: var(--black);
        margin-bottom: 20px;
        @include tab-md() {
            font-size: 95px;
            line-height: 142px;
        }
    }
}
.copyright{
    border-bottom: solid 1px var(--black);
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: end;
    justify-content: space-between;
    &:hover{
        .logo{
            filter: saturate(1);
        }
    }
    .logo{
        max-width: 155px;
        filter: saturate(0);
        @include transition(all 0.3s ease);
        img{
            max-width: 100%;
        }
    }
    .social{
        display: flex;
        align-items: center;
        gap: 10px;
        a{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            font-size: 18px;
            color: var(--title-color);
            border: solid 1px var(--title-color);
            background: transparent;
            @include border-radius(50%);
            @include transition(all 0.3s ease);
            &:hover{
                background: var(--white);
                color: var(--white);
            }
            @include tab-md() {
                width: 36px;
                height: 36px;
                font-size: 20px;
            }
        }
    }
}
.contact{
    display: flex;
    justify-content: space-between;
    @include tab-md() {
        display: flex;
        justify-content: space-between;
    }
    > div{
        @include tab-md() {
            min-width: 200px;
        }
        &:first-child{
            display: none;
            @include tab-md() {
                display: block;
            }
        }
    }
    h4{
        font-weight: 500;
        font-size: 22px;
        line-height: 33px;
        color: var(--black);
        margin-bottom: 8px;
        display: none;
        @include tab-md() {
            display: block;
        }
    }
    p{
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: var(--black);
        a{
            text-decoration: none;
            color: var(--black);
            &:hover{
                text-decoration: underline;
            }
        }
    }
}