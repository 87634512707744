@import "../../assets/scss/index.scss";

.secMarginTop {
    padding-top: 25px;
}
.leadForm {
    margin: 0 auto;
    height: 80vh;
    display: block;
    width: 100%;

    @include tab-sm() {
        width: 600px;
        height: 70vh;
    }
}
