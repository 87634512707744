
@import "../../assets/scss/index.scss";

.h1{
    font-weight: 500;
    font-size: 32px;
    line-height: 42px;
    max-width: 1150px;
    position: relative;
    margin-bottom: 30px;
    color: var(--title-color);
    @include tab-sm() {
        font-size: 56px;
        line-height: 69px;
        margin-bottom: 50px;
    }
    @include tab-md() {
        font-size: 56px;
        margin-bottom: 60px;
    }
    &:after{
        width: 0;
        height: 4px;
        position: absolute;
        left: 0;
        top: -20px;
        content: "";
        opacity: 0;
        filter: blur(4px);
        background: var(--primary-color);
        @include transition(all 0.3s ease);
        animation: fade-in-anim 0.6s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
        @include tab-sm() {
          top: -30px;
        }
        @include tab-md() {
            height: 7px;
            top: -40px;
        }
    }
    span:last-child{
        color: var(--primary-color);
    }
    span {
        opacity: 0;
        filter: blur(4px);
      }
      span:nth-child(1) {
        animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
      
      span:nth-child(2) {
        animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
      
      span:nth-child(3) {
        animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
      
      span:nth-child(4) {
        animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
      
      span:nth-child(5) {
        animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
      
      span:nth-child(6) {
        animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
      
      span:nth-child(7) {
        animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
      
      span:nth-child(8) {
        animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
      
      span:nth-child(9) {
        animation: fade-in 0.8s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
      
      span:nth-child(10) {
        animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
      
      span:nth-child(11) {
        animation: fade-in 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
      
      span:nth-child(12) {
        animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
      
      span:nth-child(13) {
        animation: fade-in 0.8s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
      
      span:nth-child(14) {
        animation: fade-in 0.8s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
      
      span:nth-child(15) {
        animation: fade-in 0.8s 1.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
      
      span:nth-child(16) {
        animation: fade-in 0.8s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
      
      span:nth-child(17) {
        animation: fade-in 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
      
      span:nth-child(18) {
        animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }
}



.h2{
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  position: relative;
  margin-bottom: 40px;
  margin-top: 15px;
  color: var(--title-color);
  @include tab-sm() {
      font-size: 46px;
      line-height: 60px;
      margin-bottom: 50px;
      margin-top: 15px;
  }
  @include tab-md() {
      font-size: 52px;
      line-height: 66px;
      margin-bottom: 60px;
  }
  &:after{
      width: 0;
      height: 4px;
      position: absolute;
      left: 0;
      bottom: -20px;
      content: "";
      opacity: 0;
      filter: blur(4px);
      background: var(--primary-color);
      @include transition(all 0.3s ease);
      animation: fade-in-anim 0.6s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
      @include tab-sm() {
        bottom: -30px;
      }
      @include tab-md() {
          height: 4.8px;
          bottom: -30px;
      }
  }
  span:last-child{
      color: var(--primary-color);
  }
  span {
      opacity: 0;
      filter: blur(4px);
    }
    span:nth-child(1) {
      animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }
    
    span:nth-child(2) {
      animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }
    
    span:nth-child(3) {
      animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }
    
    span:nth-child(4) {
      animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }
    
    span:nth-child(5) {
      animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }
    
    span:nth-child(6) {
      animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }
    
    span:nth-child(7) {
      animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }
    
    span:nth-child(8) {
      animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }
    
    span:nth-child(9) {
      animation: fade-in 0.8s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }
    
    span:nth-child(10) {
      animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }
    
    span:nth-child(11) {
      animation: fade-in 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }
    
    span:nth-child(12) {
      animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }
    
    span:nth-child(13) {
      animation: fade-in 0.8s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }
    
    span:nth-child(14) {
      animation: fade-in 0.8s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }
    
    span:nth-child(15) {
      animation: fade-in 0.8s 1.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }
    
    span:nth-child(16) {
      animation: fade-in 0.8s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }
    
    span:nth-child(17) {
      animation: fade-in 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }
    
    span:nth-child(18) {
      animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }
}


