@import "../../assets/scss/index.scss";

.slider{
    min-height: 100px;
    display: flex !important;
    align-items: center;
    img{
        margin: 0 auto;
        
    }
    .clinetimg1{
        margin: 0 auto;
        height: 50px;
        object-fit: contain;
    }
    .clinetimg2{
        margin: 0 auto;
        height: 80px;
    }
}

.slider_wrap{
    margin: 30px 0;
    @include tab-sm() {
        margin: 50px 0;
    }
    button{
        color: black;
        margin:0 10px;
        font-size: 0; /* Hide text */
        line-height: 0; /* Hide text */
        width: 20px; /* Adjust width as needed */
        height: 20px; /* Adjust height as needed */
        background: none; /* Remove default background */
        border: none; /* Remove default border */
        z-index: 1;
        &:before{
            
            color: #343434;
            @include tab-sm() {
                font-size: 30px;
            }
        }
    }
}