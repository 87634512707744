.tabStyle {
    .react-tabs {
        .react-tabs__tab-list {
            list-style-type: none;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 3px;
            justify-content: center;
            margin-bottom: 40px;

            border: none;

            @include tab-sm() {
                margin-bottom: 80px;
                gap: 15px;
            }

            @include desktop-sm() {
                gap: 15px;
            }

            li {
                // width: calc(20% - 1px);
                padding: 0 20px;
                height: 32px;

                cursor: pointer;
                display: flex;
                align-items: center;

                border: none;
                outline: none;
                justify-content: space-around;
                outline: none;
                margin:30px;
                font-size: 46.016px;
                line-height: 61px;
                /* identical to box height, or 132% */

                letter-spacing: -0.03em;

                color: var(--gray);
                margin-bottom: 5px;

                @include transition(all 0.3s ease);

                @include tab-sm() {
                    width: auto;
                    height: 53px;
                    font-size: 17px;
                    font-weight: 500;

                }

                @include desktop-sm() {
                    min-width: 130px;
                }

                &.react-tabs__tab--selected {
                    text-decoration: underline 3px #FF2E84;
                    text-underline-position: under;
                    text-underline-offset: 3px;

                    color: var(--black);
                    border: none;
                    outline: none;
                }
            }
        }
    }
}

.tabStyle1 {
    .react-tabs {
        .react-tabs__tab-list {
            list-style-type: none;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 3px;
            justify-content: space-between;
            margin-bottom: 20px;
            border: none;

            @include tab-sm() {
                margin: 80px 0 80px;
                justify-content: center;
            }

            @include desktop-sm() {
                gap: 15px;
            }

            li {
                // width: calc(20% - 1px);
                padding: 0 20px;
                height: 32px;
                cursor: pointer;
                display: flex;
                align-items: center;
                border: none;
                outline: none;
                justify-content: space-around;
                outline: none;
                margin: 0;
                font-size: 18px;
                line-height: 28px;
                padding: 6px 0 6px 0;
                // letter-spacing: -0.03em;
                color: var(--gray);
                margin-bottom: 5px;
                font-weight: 500;
                @include transition(all 0.3s ease);

                @include tab-sm() {
                    width: auto;
                    margin: 10px 15px;
                    padding: 6px 12px;
                    font-size: 46px;
                    line-height: 57px;
                    font-weight: 500;
                }


                &.react-tabs__tab--selected {
                    text-decoration: underline 3px #FF2E84;
                    text-underline-position: under;
                    text-underline-offset: 5px;
                    font-weight: 500;
                    color: var(--black);
                    border: none;
                    outline: none;
                }
                &.react-tabs__tab--selected:focus:after{
                    background: transparent;
                }
            }
        }
        .react-tabs__tab-panel {
            max-width: 980px;
            margin: 0 auto;
        }
    }
    &.tabStyletech{
        .react-tabs__tab-list{
            padding: 0 20px;
            display: block;
            @include tab-sm() {
                max-width: 1270px;
                margin: 0 auto 35px;
                padding: 0;
                display: flex;
                justify-content: space-between;
            }
            li{
                margin: 0 0 8px 0;
                justify-content: flex-start;
                @include tab-sm() {
                    font-size: 23.6864px;
                    line-height: 46px;
                    padding: 0;
                }
            }
        }
        .react-tabs__tab-panel {
            max-width: inherit;
            margin: 0 auto;
        }
    }
}

.tabStyle2 {

        .react-tabs {
            .react-tabs__tab-list {
                list-style-type: none;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-top: 30px;
                padding-left: 00px;
                gap: 3px;

                justify-content: center;
                margin-bottom: 40px;
                border: none;

                @include tab-sm() {
                    margin-bottom: 80px;
                    gap: 15px;
                }

                @include desktop-sm() {
                    gap: 15px;
                }

                li {
                    // width: calc(20% - 1px);
                    padding: 0 20px;
                    height: 32px;

                    cursor: pointer;
                    display: flex;
                    align-items: center;

                    border: none;
                    outline: none;
                    justify-content: space-around;
                    outline: none;
                    margin: 10px;
                    font-size: 23.6864px;
                    line-height: 61px;
                    /* identical to box height, or 132% */

                    letter-spacing: -0.03em;

                    color: var(--gray);
                    margin-bottom: 5px;

                    @include transition(all 0.3s ease);

                    @include tab-sm() {
                        width: auto;
                        height: 53px;
                        font-size: 17px;
                        font-weight: 500;

                    }

                    @include desktop-sm() {
                        min-width: 130px;
                    }

                    &.react-tabs__tab--selected {
                        // text-decoration: underline 3px  #FF2E84;
                        // text-underline-position: under;
                        // text-underline-offset: 5px;

                        color: var(--black);
                        border: none;
                        outline: none;
                    }
                }
            }
        }
    }


.ai-bg-image {
    background: linear-gradient(rgba(0, 0, 0, .65), rgba(0, 0, 0, .65)), url("../../img/AdobeStock.png");
    background-repeat: no-repeat;
    background-size: cover;
} 
.vr-bg-image {
    background: linear-gradient(rgba(0, 0, 0, .65), rgba(0, 0, 0, .65)), url("../../img/AdobeStock1.png");
    background-repeat: no-repeat;
    background-size: cover;
} 
.iot-bg-image {
    background: linear-gradient(rgba(0, 0, 0, .65), rgba(0, 0, 0, .65)), url("../../img/AdobeStock2.png");
    background-repeat: no-repeat;
    background-size: cover;
} 
.auto-bg-image {
    background: linear-gradient(rgba(0, 0, 0, .65), rgba(0, 0, 0, .65)), url("../../img/AdobeStock3.png");
    background-repeat: no-repeat;
    background-size: cover;
} 