@import "../../assets/scss/index.scss";

.project_info {

    .project_info_p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #636363;
        @include tab-sm() {
            font-size: 18px;
            line-height: 27px;
        }
    }

    .hyper_line {
        margin-top: 35px;
        margin-bottom: 40px;
    }
    

    .project_info1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 36px;
        letter-spacing: -0.03em;
        color: #343434;

        @include tab-sm() {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 54.8254px;
            line-height: 64px;
            width: 1171px;
            color: #343434;
            margin-top: 10px;
        }
    }

    p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #636363;
        @include tab-sm() {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 22.1257px;
            line-height: 36px;
            color: #636363;
        }
    }

}

.Project_img {


    @include tab-sm() {
        text-align: center;
        padding: 50px;
    }

    img {
        margin-top: 30px;
        width: 100%;
        max-width: 800px;
        align-items: center;
        @include tab-sm() {
            margin-top: 50px;
            max-width: 954px;
            width: 100%;
            align-items: center;
            height: 489px;
        }
    }

    p {
        font-size: 14px;
        line-height: 36px;
        color: #9A9A9A;
        @include tab-sm() {
        }
    }


}

.process_text {
    margin-top: 30px;
    
    @include tab-sm() {
        // max-width:800px;
        margin: 30px auto;
    }

    .process_text_1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 36px;
        letter-spacing: -0.03em;
        color: #343434;
        margin-bottom: 10px;
        @include tab-sm() {
            max-width: 700px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 48px;
            line-height: 67px;
            letter-spacing: -0.03em;
            margin-bottom: 10px;
            color: #000000;
        }
    }
    p{
        font-size: 16px;
        line-height: 26px;
        color: #9A9A9A;
        @include tab-sm() {
            font-size: 22px;
        }
    }
}

.project_deadline {
    margin: 30px 0;

    .project_deadline_1{
        margin: 0 0 0px;
        @include tab-sm() {
            width: calc(50% - 5px);
        }
    }
    @include tab-sm() {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        margin-bottom: 80px;
    }

    .project_deadline_text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 36px;
        letter-spacing: -0.03em;
        color: #343434;
        margin-bottom: 10px;
        @include tab-sm() {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 39.9138px;
            line-height: 52px;
            letter-spacing: -0.04em;
            color: #000000;
        }

    }
}

.project_deadline_text1 {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #343434;
    margin-bottom: 10px;
    @include tab-sm() {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 36px;
        color: #343434;
    }
}

.project_deadline_text2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #636363;

    @include tab-sm() {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 19.9937px;
        line-height: 32px;
        /* or 162% */


        color: #636363;
    }

}

.project_deadline1 {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 100px;

    @include tab-sm() {
        flex-direction: row;
        gap: 40px;
    }

    .project_deadline_text1 {
        height: 52px;
        left: 483px;
        top: 1803.12px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 39.9138px;
        line-height: 52px;
        /* identical to box height, or 129% */

        letter-spacing: -0.04em;

        color: #000000;

        @include tab-sm() {
            height: 52px;
            left: 483px;
            top: 1803.12px;
            margin-left: 200px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 39.9138px;
            line-height: 52px;
            /* identical to box height, or 129% */

            letter-spacing: -0.04em;
        }
    }
}

.project_deadline_text_1 {
    width: 148px;


    height: 36px;
    left: 914px;
    top: 1559.13px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22.2262px;
    line-height: 36px;
    /* identical to box height, or 162% */


    color: #343434;

    @include tab-sm() {
        width: 148px;
        margin-left: 120px;

        height: 36px;
        left: 914px;
        top: 1559.13px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 22.2262px;
        line-height: 36px;
        /* identical to box height, or 162% */


        color: #343434;
    }
}

.project_deadline_text_2 {
    width: 341px;
    height: 98px;
    left: 914px;
    top: 1603.41px;



    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 19.9937px;
    line-height: 32px;
    /* or 162% */


    color: #636363;

    @include tab-sm() {
        width: 461px;
        height: 98px;
        left: 914px;
        top: 1603.41px;
        margin-left: 120px;


        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 19.9937px;
        line-height: 32px;
        /* or 162% */


        color: #636363;
    }

}

.programming_language {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 20px;

    @include tab-sm() {
        flex-direction: row;
        gap: 30px;
        justify-content: center;
        text-align: center;
    }
}


.about {
    padding: 20px 0;

    @include desktop-sm() {
        padding: 100px 0;
    }
}


.caseBg {

    padding: 50px 0 10px;
    // background: #000000 url("../../assets/images/casestudy_bg.jpg") no-repeat left bottom;
    background-size: 100%;
    text-align: center;


    @include desktop-sm() {
        padding: 50px 0;
        // background-size:cover;
        // background-attachment: fixed;

    }


    .project_descover {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include tab-sm() {
            flex-direction: row;
            gap: 40px;
        }

        .project_descover_text {
            width: 168px;
            height: 52px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 38px;
            line-height: 52px;
            color: #000000;
            
        }
    }

    .project_descover_text1 {
        width: 148px;
        margin-left: 200px;
        height: 36px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 22.2262px;
        line-height: 36px;
        color: #343434;


    }

    .project_descover_text2 {
        width: 461px;
        height: 98px;
        margin-left: 200px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 19.9937px;
        line-height: 32px;
        color: #636363;
    }

    .project_descover {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 200px;

        @include tab-sm() {
            flex-direction: row;
            gap: 40px;
        }

        .project_descover_text {
            width: 168px;
            height: 52px;
            margin-left: 200px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 38px;
            line-height: 52px;
            color: #000000;
            @include tab-sm() {
            }

        }
    }

    .project_descover_text1 {
        width: 148px;
        margin-left: 200px;

        height: 36px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 22.2262px;
        line-height: 36px;
        /* identical to box height, or 162% */


        color: #343434;


    }

    .project_descover_text2 {
        margin-left: 200px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #636363;
    }
}

.step1 {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top:30px;
    text-align: left;
    @include tab-sm() {
        flex-direction: row;
        gap: 40px;
    }

    .process_step1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;

        @include tab-sm() {

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
        }
    }

    .process_activity1 {
        width: 81px;
        height: 27px;


        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #000000;

        @include tab-sm() {
            width: 81px;
            height: 27px;
            left: 1109.8px;
            top: 5231.59px;
            margin-left: 450px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: #000000;
        }
    }
}

.process_discover {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include tab-sm() {
        flex-direction: row;
        gap: 40px;
    }

    .process_descover_text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 52px;
        color: #000000;
        text-align: left;
        @include tab-sm() {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 38px;
            line-height: 52px;
            color: #000000;
            width: 40%;
            min-width: 40%;
        }

    }

    .process_descover_text_p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size:16px;
        line-height: 24px;
        color: #343434;
        @include tab-sm() {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            margin-left: 0px;
            color: #343434;
        }
    }
}

.process_descover_01 {
    margin-top: 20px;

    @include tab-sm() {
        margin-top: 10px;

    }
}

.process_descover_1_number {
    display: flex;
    align-items: center;
    text-align: left;
    gap: 10px;
    margin-bottom: 10px;
    @include tab-sm() {
        flex-direction: row;
        margin-top: 0;
    }

    .process_descover_1_number_1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        color: #FF2E84;
    }

    .process_descover_text1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        color: #000000;
        @include tab-sm() {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 30px;
            color: #000000;
        }
    }

    .process_descover_text1_2 {

        width: 388px;
        height: 80px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 26.7671px;
        line-height: 40px;
        color: #000000;

        @include tab-sm() {
            width: 418px;
            height: 50px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 26.7671px;
            line-height: 40px;

            color: #000000;
        }
    }

    .process_descover_text1_3 {
        width: 353px;
        height: 80px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 26.7671px;
        line-height: 40px;

        color: #000000;

        @include tab-sm() {
            width: 383px;
            height: 50px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 26.7671px;
            line-height: 40px;

            color: #000000;
        }
    }
}

.process_descover_text2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size:16px;
    line-height: 24px;
    margin-left: 32px;
    color: #343434;
    margin-bottom: 30px;
    text-align: left;
    @include tab-sm() {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        margin-left: 32px;
        color: #343434;
        margin-bottom: 60px;
    }

}

.process_descover_text2_2 {
    width: 290px;
    height: 188px;
    left: 1110px;
    top: 5720px;
    text-align: justify;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20.0753px;
    line-height: 32px;
    /* or 162% */
    margin-left: 40px;
    margin-bottom: 60px;
    color: #343434;

    @include tab-sm() {
        flex-direction: row;
        width: 370px;
        height: 158px;
        left: 1110px;
        top: 5720px;
        text-align: justify;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20.0753px;
        line-height: 32px;
        /* or 162% */
        margin-left: 40px;
        margin-bottom: 60px;
        color: #343434;
    }
}

.process_descover_text2_3 {
    width: 290px;
    height: 168px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20.0753px;
    line-height: 32px;
    /* or 162% */
    text-align: justify;
    margin-left: 40px;
    margin-bottom: 60px;
    color: #343434;

    @include tab-sm() {
        width: 370px;
        height: 168px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20.0753px;
        line-height: 32px;
        /* or 162% */
        text-align: justify;
        margin-left: 40px;
        margin-bottom: 60px;
        color: #343434;
    }
}




.step2 {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 50px;

    @include tab-sm() {
        flex-direction: row;
        gap: 40px;
    }

    .process_step2 {
        width: 59px;
        height: 24px;

        margin-left: 20px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */


        color: #000000;

        @include tab-sm() {
            width: 59px;
            height: 24px;
            margin-left: 140px;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height */


            color: #000000;

        }
    }

    .process_activity2 {
        width: 81px;
        height: 27px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */


        color: #000000;

        @include tab-sm() {
            width: 81px;
            height: 27px;
            left: 1109.8px;
            top: 5231.59px;
            margin-left: 435px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            /* identical to box height */


            color: #000000;

        }
    }
}

.process_discover_1 {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include tab-sm() {
        flex-direction: row;
        gap: 40px;
    }

    .process_descover_text_1 {
        width: 168px;
        height: 52px;
        left: 483px;
        top: 1551px;


        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 39.9138px;
        line-height: 52px;
        /* identical to box height, or 129% */

        letter-spacing: -0.04em;

        color: #000000;

        @include tab-sm() {
            width: 168px;
            height: 52px;
            left: 483px;
            top: 1551px;
            margin-left: 120px;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 39.9138px;
            line-height: 52px;
            /* identical to box height, or 129% */

            letter-spacing: -0.04em;

            color: #000000;

        }
    }

    .process_descover_text_p_1 {
        width: 310px;
        height: 98px;
        left: 483px;
        top: 6056.53px;
        text-align: justify;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20.0753px;
        line-height: 32px;
        /* or 162% */
        margin-left: 20px;

        color: #343434;

        @include tab-sm() {
            width: 310px;
            height: 98px;
            left: 483px;
            top: 6056.53px;
            text-align: justify;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 20.0753px;
            line-height: 32px;
            /* or 162% */
            margin-left: 20px;

            color: #343434;

        }
    }
}

.process_descover_02 {
    margin-top: 200px;

    @include tab-sm() {
        margin-left: 300px;
        margin-top: 10px;
    }

    .process_descover_1_number_1 {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include tab-sm() {
            flex-direction: row;
            gap: 40px;
        }

        .process_descover_1_number_1_1 {

            width: 2px;
            height: 28px;
            left: 1074.85px;
            top: 5280.74px;
            margin-top: 5px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 18.5883px;
            line-height: 28px;
            /* identical to box height */


            color: #FF2E84;
        }

        .process_descover_text1_1 {
            width: 254px;
            height: 50px;


            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 26.7671px;
            line-height: 40px;

            color: #000000;


        }

        .process_descover_text1_2_1 {

            width: 266px;
            height: 50px;
            left: 1109.8px;
            top: 5476.29px;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 26.7671px;
            line-height: 40px;

            color: #000000;

            @include tab-sm() {

                width: 266px;
                height: 50px;
                left: 1109.8px;
                top: 5476.29px;

                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 26.7671px;
                line-height: 40px;

                color: #000000;

            }
        }

        .process_descover_text1_3_1 {
            width: 135px;
            height: 40px;
            left: 1109.8px;
            top: 5679.28px;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 26.7671px;
            line-height: 40px;

            color: #000000;

        }
    }

    .process_descover_text2_1 {
        width: 270px;
        height: 268px;
        left: 1109.8px;
        top: 5314.2px;
        text-align: justify;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20.0753px;
        line-height: 32px;
        /* or 162% */

        margin-left: 40px;
        color: #343434;
        ;
        margin-bottom: 60px;

        @include tab-sm() {
            width: 370px;
            height: 198px;
            left: 1109.8px;
            top: 5314.2px;
            text-align: justify;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 20.0753px;
            line-height: 32px;
            /* or 162% */

            margin-left: 40px;
            color: #343434;
            ;
            margin-bottom: 60px;

        }

    }

    .process_descover_text2_2_1 {
        width: 270px;
        height: 348px;
        left: 1110px;
        top: 5720px;
        text-align: justify;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20.0753px;
        line-height: 32px;
        /* or 162% */
        margin-left: 40px;
        margin-bottom: 60px;
        color: #343434;

        @include tab-sm() {
            width: 370px;
            height: 228px;
            left: 1110px;
            top: 5720px;
            text-align: justify;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 20.0753px;
            line-height: 32px;
            /* or 162% */
            margin-left: 40px;
            margin-bottom: 60px;
            color: #343434;

        }
    }

    .process_descover_text2_3_1 {
        width: 240px;
        height: 298px;
        left: 1110px;
        top: 5720px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20.0753px;
        line-height: 32px;
        /* or 162% */
        text-align: justify;
        margin-left: 40px;
        margin-bottom: 60px;
        color: #343434;

        @include tab-sm() {
            width: 370px;
            height: 198px;
            left: 1110px;
            top: 5720px;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 20.0753px;
            line-height: 32px;
            /* or 162% */
            text-align: justify;
            margin-left: 40px;
            margin-bottom: 60px;
            color: #343434;

        }
    }

}


.step3 {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 50px;

    @include tab-sm() {
        flex-direction: row;
        gap: 40px;
    }

    .process_step3 {
        width: 59px;
        height: 24px;


        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */


        color: #000000;

        @include tab-sm() {
            width: 59px;
            height: 24px;
            margin-left: 140px;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height */


            color: #000000;

        }
    }

    .process_activity3 {
        width: 11px;
        height: 27px;
        left: 1109.8px;
        top: 5231.59px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */


        color: #000000;

        @include tab-sm() {
            width: 151px;
            height: 27px;
            left: 1109.8px;
            top: 5231.59px;
            margin-left: 400px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            /* identical to box height */


            color: #000000;

        }
    }
}

.process_discover_3 {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include tab-sm() {
        flex-direction: row;
        gap: 40px;
    }

    .process_descover_text_3 {
        width: 104px;
        height: 52px;



        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 39.9138px;
        line-height: 52px;

        letter-spacing: -0.04em;

        color: #000000;

        @include tab-sm() {
            width: 104px;
            height: 52px;

            margin-left: 140px;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 39.9138px;
            line-height: 52px;

            letter-spacing: -0.04em;

            color: #000000;

        }
    }

    .process_descover_text_p_3 {
        width: 310px;
        height: 98px;

        text-align: justify;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20.0753px;
        line-height: 32px;


        color: #343434;

        @include tab-sm() {
            width: 310px;
            height: 98px;

            text-align: justify;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 20.0753px;
            line-height: 32px;


            color: #343434;

        }
    }
}

.process_descover_03 {
    margin-top: 200px;

    @include tab-sm() {
        margin-top: 10px;
        margin-left: 350px;
    }

    .process_descover_1_number_3 {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include tab-sm() {
            flex-direction: row;
            gap: 40px;
        }

        .process_descover_1_number_1_3 {

            width: 2px;
            height: 28px;
            left: 1074.85px;
            top: 5280.74px;
            margin-top: 5px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 18.5883px;
            line-height: 28px;
            /* identical to box height */
            color: #FF2E84;
        }

        .process_descover_text1_3 {
            width: 341px;
            height: 80px;
            left: 1109.8px;
            top: 5273.31px;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 26.7671px;
            line-height: 40px;

            color: #000000;

            @include tab-sm() {

                width: 361px;
                height: 50px;
                left: 1109.8px;
                top: 5273.31px;

                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 26.7671px;
                line-height: 40px;

                color: #000000;

            }

        }

        .process_descover_text1_2_3 {

            width: 148px;
            height: 50px;
            left: 1109.8px;
            top: 5476.29px;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 26.7671px;
            line-height: 40px;

            color: #000000;

            @include tab-sm() {

                width: 148px;
                height: 50px;
                left: 1109.8px;
                top: 5476.29px;

                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 26.7671px;
                line-height: 40px;

                color: #000000;

            }
        }

        .process_descover_text1_3_3 {
            width: 302px;
            height: 50px;
            left: 1109.8px;
            top: 5679.28px;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 26.7671px;
            line-height: 40px;

            color: #000000;

        }
    }

    .process_descover_text2_3 {
        width: 290px;
        height: 178px;
        left: 1109.8px;
        top: 5314.2px;
        text-align: justify;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20.0753px;
        line-height: 32px;
        /* or 162% */

        margin-left: 40px;
        color: #343434;
        ;
        margin-bottom: 60px;

        @include tab-sm() {
            width: 370px;
            height: 178px;
            left: 1109.8px;
            top: 5314.2px;
            text-align: justify;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 20.0753px;
            line-height: 32px;
            /* or 162% */

            margin-left: 40px;
            color: #343434;
            ;
            margin-bottom: 60px;

        }

    }

    .process_descover_text2_2_3 {
        width: 290px;
        height: 158px;
        left: 1110px;
        top: 5720px;
        text-align: justify;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20.0753px;
        line-height: 32px;
        /* or 162% */
        margin-left: 40px;
        margin-bottom: 60px;
        color: #343434;

        @include tab-sm() {
            width: 370px;
            height: 158px;
            left: 1110px;
            top: 5720px;
            text-align: justify;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 20.0753px;
            line-height: 32px;
            /* or 162% */
            margin-left: 40px;
            margin-bottom: 60px;
            color: #343434;

        }

    }

    .process_descover_text2_3_3 {
        width: 290px;
        height: 178px;
        left: 1110px;
        top: 5720px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20.0753px;
        line-height: 32px;
        /* or 162% */
        text-align: justify;
        margin-left: 40px;
        margin-bottom: 60px;
        color: #343434;

        @include tab-sm() {
            width: 370px;
            height: 178px;
            left: 1110px;
            top: 5720px;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 20.0753px;
            line-height: 32px;
            /* or 162% */
            text-align: justify;
            margin-left: 40px;
            margin-bottom: 60px;
            color: #343434;


        }
    }

}


.step4 {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 50px;

    @include tab-sm() {
        flex-direction: row;
        gap: 40px;
    }

    .process_step4 {
        width: 60px;
        height: 24px;


        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */


        color: #000000;

        @include tab-sm() {
            width: 60px;
            height: 24px;
            margin-left: 140px;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height */


            color: #000000;

        }
    }

    .process_activity4 {
        width: 11px;
        height: 27px;
        left: 1109.8px;
        top: 5231.59px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */


        color: #000000;

        @include tab-sm() {
            width: 151px;
            height: 27px;
            left: 1109.8px;
            top: 5231.59px;
            margin-left: 390px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            /* identical to box height */


            color: #000000;

        }
    }
}

.process_discover_4 {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include tab-sm() {
        flex-direction: row;
        gap: 40px;
    }

    .process_descover_text_4 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 39.9138px;
        line-height: 52px;
        color: #000000;

        @include tab-sm() {
            margin-left: 110px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 39.9138px;
            line-height: 52px;
            color: #000000;

        }

    }

    .process_descover_text_p_4 {
        width: 310px;
        height: 98px;
        left: 483px;
        top: 6056.53px;

        text-align: justify;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20.0753px;
        line-height: 32px;
        /* or 162% */


        color: #343434;

        @include tab-sm() {
            width: 310px;
            height: 98px;

            text-align: justify;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 20.0753px;
            line-height: 32px;
            /* or 162% */
            margin-left: 20px;

            color: #343434;
        }
    }
}

.process_descover_04 {
    margin-top: 270px;

    @include tab-sm() {
        margin-left: 270px;
        margin-top: 10px;

    }

    .process_descover_1_number_4 {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include tab-sm() {
            flex-direction: row;
            gap: 40px;
        }

        .process_descover_1_number_1_4 {

            width: 2px;
            height: 28px;
            left: 1074.85px;
            top: 5280.74px;
            margin-top: 5px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 18.5883px;
            line-height: 28px;
            /* identical to box height */


            color: #FF2E84;
        }

        .process_descover_text1_4 {
            width: 273px;
            height: 50px;
            left: 1109.8px;
            top: 5273.31px;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 26.7671px;
            line-height: 40px;

            color: #000000;


        }

        .process_descover_text1_2_4 {

            height: 50px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 26.7671px;
            line-height: 40px;

            color: #000000;
        }

        .process_descover_text1_3_4 {
            width: 248px;
            height: 50px;
            left: 1109.8px;
            top: 5679.28px;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 26.7671px;
            line-height: 40px;

            color: #000000;

        }
    }

    .process_descover_text2_4 {
        width: 290px;
        height: 298px;
        left: 1109.8px;
        top: 5314.2px;
        text-align: justify;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20.0753px;
        line-height: 32px;
        /* or 162% */

        margin-left: 40px;
        color: #343434;
        ;
        margin-bottom: 60px;

        @include tab-sm() {
            width: 370px;
            height: 208px;
            left: 1109.8px;
            top: 5314.2px;
            text-align: justify;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 20.0753px;
            line-height: 32px;
            margin-left: 40px;
            color: #343434;
            ;
            margin-bottom: 60px;

        }

    }

    .process_descover_text2_2_4 {
        width: 290px;
        height: 208px;
        text-align: justify;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20.0753px;
        line-height: 32px;
        margin-left: 40px;
        margin-bottom: 60px;
        color: #343434;

        @include tab-sm() {
            width: 370px;
            height: 208px;
            text-align: justify;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 20.0753px;
            line-height: 32px;
            margin-left: 40px;
            margin-bottom: 60px;
            color: #343434;
        }

    }

    .process_descover_text2_3_4 {
        width: 290px;
        height: 198px;
        left: 1110px;
        top: 5720px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20.0753px;
        line-height: 32px;
        text-align: justify;
        margin-left: 40px;
        margin-bottom: 60px;
        color: #343434;

        @include tab-sm() {
            width: 370px;
            height: 198px;
            left: 1110px;
            top: 5720px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 20.0753px;
            line-height: 32px;
            text-align: justify;
            margin-left: 40px;
            margin-bottom: 60px;
            color: #343434;
        }
    }

}


.futureplan {
    margin-top: 40px;
    margin-bottom: 30px;
    background-color: #EFEFEF;
    padding: 50px 0;
    @include tab-sm() {
        margin-top: 80px;
        margin-bottom: 70px;
        padding: 0 0;
        background-image: url(../../assets/img/Group23.png);
        background-repeat: no-repeat;
        background-size: contain;
        padding-bottom: 200px;
        background-position: center 60px;
    }
    .futuretechnology {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin: 0;
        @include tab-sm() {
            flex-direction: row;
            justify-content: space-between;
        }
        .artificial{
            margin-bottom: 50px;
            max-width: 540px;
            padding-left: 120px;
        }
        .text {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 50px;
            letter-spacing: -0.03em;
            color: #000000;
            margin-bottom: 0;
            
            @include tab-sm() {
                padding-top: 100px;
            }
            .year {
                color: #FF2E84;
                margin-top: 15px;
                font-size: 52.8323px;
                display: block;
                @include tab-sm() {
                    margin-top:10px;
                    font-size: 80px;
                    display: inline-block;
                }
            }
            .para{
                font-size: 22px;
                line-height: 34px;
                letter-spacing:0;
                color: #343434;
                margin-top: 20px;
                max-width: 640px;
            }
            p{
                font-size: 32px;
                line-height: 60px;
                font-weight: 500;
                @include tab-sm() {
                    font-size: 64px;
                    line-height: 80px;
                }
            }
            @include tab-sm() {
                font-size: 52px;
                line-height: 80px;
                max-width: 660px;
                width: 100%;
            }
        }


        .text2 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 36px;
            margin-top: 0;
            letter-spacing: -0.03em;
            color: #000000;
            margin-bottom: 5px;
            @include tab-sm() {
                margin-top: 220px;
                width: 500px;
                height: 72px;
                font-size: 50px;
                line-height: 76px;
            }
        }

        .text3 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size:14px;
            line-height: 24px;
            color: #343434;
        }
        @include tab-sm() {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size:14px;
            line-height: 24px;
            color: #343434;

            .text3 {
                font-size: 22px;
                line-height: 36px;
            }
        }
        
    }

    .virtualreality {
        margin-bottom: 30px;
        @include tab-sm() {
            margin-left: 360px;
            margin-top: 65px;
        }
        .virtualrealitytext {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 46px;
            letter-spacing: -0.03em;
            color: #000000;
            @include tab-sm() {
                font-size:50px;
                line-height:66px;
               width: 450px;
            }
        }
        .virtualrealitytext1 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size:14px;
            line-height: 24px;
            color: #343434;
            @include tab-sm() {
                width: 450px;
                font-size: 22px;
                line-height: 36px;
                margin-top: 5px;
            }
        }
    }
    .internetofthings {
        margin-left: 0;
        
        @include tab-sm() {
            margin-left: 580px;
            margin-top: 110px;
           
        }
        .internetofthingstext {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 46px;
            letter-spacing: -0.03em;
      
           
            color: #000000;
            @include tab-sm() {
                font-size:50px;
                line-height:66px;
            }
        }
        .internetofthingstext1 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size:14px;
            line-height: 24px;
            color: #343434;
            margin-bottom: 30px;
            @include tab-sm() {
                font-size: 22px;
                line-height: 36px;
                margin-top: 5px;
                padding-bottom: 15px;
            }
         
        }
        @include tab-sm() {
            width: 539px;
        }
    }
    .automation {
        margin: 0;
        @include tab-sm() {
            margin-top: 140px;
            margin-left: 515px;
         

        }
        .automationtext {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 46px;
            letter-spacing: -0.03em;
      
           
            color: #000000;
            @include tab-sm() {
                font-size:50px;
                line-height:66px;
               width: 450px;
            }
        }
        .automationtext1 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size:14px;
            line-height: 24px;
            color: #343434;
            @include tab-sm() {
                width: 500px;
                font-size: 22px;
                line-height: 36px;
                margin-top: 5px;
                padding-bottom: 15px;
            }
           
        }
        @include tab-sm() {
            width: 500px;
        }
    }
}

.transfer {
    margin-top: 70px;
    .text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        line-height: 72px;
        letter-spacing: -0.03em;
        color: #000000;

        @include tab-sm() {
            width: 685px;
            height: 204px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 48px;
            line-height: 72px;
            letter-spacing: -0.03em;
            color: #000000;
        }
    }

    .text1 {
        width: 358px;
        left: 311px;
        top: 8339px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 22.1257px;
        line-height: 36px;
        color: #636363;
    }
}

.btntext {
    margin-top: 20px;

    @include tab-sm() {
    }
}

.scrollTop {
    width: 45px;
    height: 45px;
    position: fixed;
    z-index: 5;
    right: 20px;
    bottom: 75px;
    display: block;
    border: none;
    cursor: pointer;
    text-indent: -9999px;
    background: rgba(255, 255, 255, 0.5) url("../../assets/img/arrow.png") no-repeat center center;
    background-size: 100%;
    transition: all 0.3s ease;
  }
  @media (min-width: 1024px) {
    .scrollTop {
      right: 20px;
      bottom: 75px;
    }
  }
  .scrollTop:hover {
    background: rgba(255, 255, 255, 0.1) url("../../assets/img/arrow.png") no-repeat center center;
    background-size: 100%;
  }
  
  
.programming_language_text {
    margin-top: 50px;
    text-align: center;
    p{
        font-size: 16px;
        line-height: 26px;
        color: #9A9A9A;
    }

    .programming_language_text_p {

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 36px;
        letter-spacing: -0.03em;
        color: #343434;
        margin-bottom: 6px;
        @include tab-sm() {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 42px;
            line-height: 52px;
            letter-spacing: -0.03em;
            margin-bottom: 10px;
            color: #000000;
        }
    }
}

.programming_language {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 20px;
    justify-content: center;
    text-align: center;

    @include tab-sm() {
        flex-direction: row;
        gap: 50px;
        margin-top: 50px;
    }

    .programming_language_img {
        width: 150px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;

        img {
            max-width: 100%;
            height: 50px;
            object-fit: contain;
        }

        @include tab-sm() {
            width: 208px;
            height: 70px;
            margin: 10px;
            img {
                height: auto;
            }
        }

        img {
            margin: 0;
        }
    }
}