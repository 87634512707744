@import "../../assets/scss/index.scss";

@media only screen and (min-width: 740px) {
  .container .container-fluid header .header-nav-area #nav_container {
    display: flex;
  }
}

.project_info1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: -0.03em;
  margin-top: 10px;
  color: #343434;

  @include tab-sm() {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 54.8254px;
    line-height: 64px;
    letter-spacing: -0.03em;

    color: #343434;
  }
}



  @include tab-sm() {
    .hyper_line {
      margin-top: 30px;
      margin-bottom: 65px;
    }
  }



  @include tab-sm() {
    .p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 20.2819px;
      line-height: 33px;
      /* or 162% */
      color: #636363;

    }


  }


@include tab-sm() {
  .UiUx {
    margin-Left: "80px";
    margin-Bottom: "50px"
  }
}

@include tab-sm() {
  .meta {
    padding: 0%;
    color: #636363;

    @include tab-sm() {
      .reserch {
        color: #FF2E84;
      }
    }
  }

  @include tab-sm() {
    .ui {
      position: absolute;
      top: 145%;
      right: 330px;
      color: white;
    }
  }

  @include tab-sm() {
    .it {
      position: absolute;
      top: 150%;
      right: 300px;
      color: white;
    }
  }

  @include tab-sm() {
    .ux {
      position: absolute;
      top: 145%;
      left: 100px;
      color: white;
    }
  }

  @include tab-sm() {
    .design {
      color: #FF2E84;
    }
  }

  @include tab-sm() {
    .why {
      color: #28141E;
    }
  }

  @include tab-sm() {
    .rch {
      color: #FF2E84;
    }
  }

  @include tab-sm() {
    .ua {
      position: absolute;
      top: 145%;
      left: 550px;
      color: white;
    }
  }

  @include tab-sm() {
    .ie {
      position: absolute;
      top: 150%;
      left: 550px;
      color: white;
    }
  }


}

@include tab-sm() {
  .getConect {
    color: hotpink;
  }
}

@include tab-sm() {
  .rowImg {
    display: flex;
    align-items: center;
    justify-content: center;

  }
}

@include tab-sm() {
  .btn-col {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@include tab-sm() {
  .ba {
    font-weight: 900;
    font-family: Poppins;
    font-size: 50px;
    color: #000000;
    font-weight: bold;

  }
}

@include tab-sm() {
  .bb {
    font-Weight: bold;
    font-Size: 50px;
    color: #636363;
    font-family: Poppins;
  }
}

@include tab-sm() {
  .bc {
    font-Weight: bold;
    font-Size: 35px;

  }
}

@include tab-sm() {
  .marg {
    margin: 25px 25%;
  }
}


@include tab-sm() {
  .ac {

    font-Weight: bold;
    font-Size: 35px;
  }
}

@include tab-sm() {
  .btn-col button {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 22px;
    color: rgb(87, 88, 88);
    margin: 0 50px;
  }
}

@include tab-sm() {
  .below-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ok {
  background: #FF2E84;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: block;
  text-indent: -999px;
  margin-right: 10px;
}


@include tab-sm() {
  .bw {
    margin-Left: 30px;
    margin-Right: 800px;
  }
}

@include tab-sm() {
  .ar {
    margin: 0px 12px 10px 210px;

  }
}

@include tab-sm() {
  .ha {
    margin: 25px 25%;
    display: flex;
    align-Items: center;
    justify-Content: space-between;
  }
}

@include tab-sm() {
  .sh {
    max-width: 100%;
    // margin-Left: 25px;
  }
}

@include tab-sm() {
  .shw {
    display: flex;
    align-Items: center;

  }
}

@include tab-sm() {
  .re {
    margin-Left: 25px;
  }
}

@include tab-sm() {
  .uiuxteam_metasoft {
    position: relative;

  }
}

.kk {
  display: flex;

}

@include tab-sm() {
  .kk {
    display: flex;
    padding-bottom: 20px;
    align-items: center;
  }
}

@include tab-sm() {
  .ka {
    margin-left: 25px;
  }
}

@include tab-sm() {
  .na {

    display: flex;
    flex-wrap: wrap;
  }
  .kk{
    width: 33.3%;
  }
}

.ot {
  img {
    max-width: 100%;
  }
}

.titlerap {
  display: flex;
  align-items: center;
}

@include tab-sm() {
  .pk {

    color: #28141E;

  }
}

@include tab-sm() {
  .arshi {
    margin-Top: 25px;
    color: #858585;
    width: 300px;
    font-size: smaller;
  }
}

@include tab-sm() {
  .kq {
    position: absolute;
    top: 150%;
    left: 100px;
    color: white;
  }
}


@include tab-sm() {
  .en {
    display: block;
    margin-Left: 5%;
    align-items: center;
  }
}




.scrollTop {
  width: 45px;
  height: 45px;
  position: fixed;
  z-index: 5;
  right: 20px;
  bottom: 75px;
  display: block;
  border: none;
  cursor: pointer;
  text-indent: -9999px;
  background: rgba(255, 255, 255, 0.5) url("../../assets/img/arrow.png") no-repeat center center;
  background-size: 100%;
  transition: all 0.3s ease;
}
@media (min-width: 1024px) {
  .scrollTop {
    right: 20px;
    bottom: 75px;
  }
}
.scrollTop:hover {
  background: rgba(255, 255, 255, 0.1) url("../../assets/img/arrow.png") no-repeat center center;
  background-size: 100%;
}


.uiuxteam {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  gap: 16px;
  margin-top: 50px;
  margin-bottom: 40px;

  @include tab-sm() {
    flex-direction: row;
    gap: 40px;
  }
}

.uiuxteam_metasoft {
  position: relative;
}

.uiuximg11 {
  max-width: 388px;
  height: 410.75px;
  background: linear-gradient(rgba(0, 0, 0, .30), rgba(0, 0, 0, .30)), url("../../assets/img/ui-ux\ img1.png");
  background-repeat: no-repeat;
  background-size: cover;

  @include tab-sm() {

    width: 387.34px;
    height: 410.75px;
    background: linear-gradient(rgba(0, 0, 0, .30), rgba(0, 0, 0, .30)), url("../../assets/img/ui-ux\ img1.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.uiuximg21 {
  max-width: 388px;
  height: 410.75px;
  background: linear-gradient(rgba(0, 0, 0, .30), rgba(0, 0, 0, .30)), url("../../assets/img/ui-ux\ img2.png");
  background-repeat: no-repeat;
  background-size: cover;

  @include tab-sm() {
    width: 387.34px;
    height: 410.75px;
    background: linear-gradient(rgba(0, 0, 0, .30), rgba(0, 0, 0, .30)), url("../../assets/img/ui-ux\ img2.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.uiuximg31 {
  max-width: 387.34px;
  height: 410.75px;
  background: linear-gradient(rgba(0, 0, 0, .30), rgba(0, 0, 0, .30)), url("../../assets/img/ui-ux\ img3.png");
  background-repeat: no-repeat;
  background-size: cover;

  @include tab-sm() {
    width: 387.34px;
    height: 410.75px;
    background: linear-gradient(rgba(0, 0, 0, .30), rgba(0, 0, 0, .30)), url("../../assets/img/ui-ux\ img3.png");
    background-repeat: no-repeat;
    background-size: cover;

  }
}

.uiuxblog {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 31px;
  color: #FF2E84;

  margin-top: 20px;

  @include tab-sm() {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 31px;
    color: #FF2E84;

    margin-top: 20px;
  }
}

.uiuxblogtext {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 17.2067px;
  line-height: 28px;
  /* or 163% */
  margin-top: 10px;
  color: #28141E;

  @include tab-sm() {

    width: 387px;
    height: 60px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    margin-top: 10px;
    color: #28141E;
  }

}

.uiuxblogtext1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 21px;
  /* or 142% */
  margin-top: 20px;
  color: #636363;

  @include tab-sm() {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 21px;
    /* or 142% */
    text-align: justify;
    margin-top:15px;
    width: 362px;
    height: 68px;
    color: #636363;
  }
}

.textonimage {
  position: absolute;
  margin-left: 30px;
  margin-top: 320px;
  color: #FFFFFF;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 19.5937px;
  line-height: 164%;
  /* or 65px */
  text-align: justify;
  letter-spacing: -0.03em;
  font-weight: 400;
  @include tab-sm() {

    position: absolute;
    margin-left: 30px;
    margin-top: 320px;
    color: #FFFFFF;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19.5937px;
    line-height: 164%;
    /* or 65px */
    text-align: justify;
    letter-spacing: -0.03em;
  }
  @include tab-sm() {
    h3{
      font-weight: 400;
      font-size: 40px;
      margin-bottom: 5px;
    }
    p{
      font-weight: 400;
    }
  }
}

.uiuxteam_metasoft_1 {
  position: relative;
  
}


.textonimage2 {
  position: absolute;
  margin-left: 30px;
  margin-top: 1600px;
  color: #FFFFFF;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 19.5937px;
  line-height: 164%;
  /* or 65px */
  text-align: justify;
  letter-spacing: -0.03em;

  @include tab-sm() {

    position: absolute;
    margin-left: 880px;
    margin-top: 320px;
    color: #FFFFFF;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19.5937px;
    line-height: 164%;
    /* or 65px */
    text-align: justify;
    letter-spacing: -0.03em;

  }
  @include tab-sm() {
    h3{
      font-weight: 400;
      font-size: 40px;
      margin-bottom: 5px;
    }
    p{
      font-weight: 400;
    }
  }
}



.uiuxteam_metasoft_1 {
  position: relative;

  @include tab-sm() {

    position: relative;
  }
}

.uiuxtea_mmetasoft_2 {
  position: relative;

  @include tab-sm() {

    position: relative;
  }
}

.textonimage1 {
  position: absolute;
  margin-left: 30px;
  margin-top: 964px;
  color: #FFFFFF;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 19.5937px;
  line-height: 164%;
  /* or 65px */
  text-align: justify;
  letter-spacing: -0.03em;

  @include tab-sm() {
    position: absolute;
    margin-left: 460px;
    margin-top: 320px;
    color: #FFFFFF;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19.5937px;
    line-height: 164%;
    text-align: justify;
    letter-spacing: -0.03em;

  }
  @include tab-sm() {
    h3{
      font-weight: 400;
      font-size: 40px;
      margin-bottom: 5px;
    }
    p{
      font-weight: 400;
    }
  }
}

.ar {
  margin: 10px 0;
  @include tab-sm() {
    font-size: 25px;
    line-height: 40px;
    margin: 15px 0;
  }
}

.marg {
  margin: 15px 0;

  .na {
    margin-bottom: 25px;
  }

  .kk {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;

    h2 {
      font-size: 18px;
    }
    @include tab-sm() {
      h2{
        margin: 0;
        font-size: 37px;
        line-height: 44px;
        font-weight: 400;
        white-space: nowrap;
      }
      img{
        width: 66px;
        height: 66px;
        object-fit: contain;
      }
    }
  }
}

.titlerap {
  h2 {
    margin: 0;
  }
  @include tab-sm() {
    position: relative;
    align-items: center;
    h1{
      position: absolute;
      left: -80px;
      top: 15px;
      width: 34px;
      height: 34px;

    }
    h2{
      font-size: 49.1718px;
      line-height: 57px;
      font-weight: 500;
    }
  }
}

.arshi {
  margin-bottom: 15px;
}

.ot {
  img {
    margin-bottom: 10px;
  }

  h2 {
    margin-bottom: 10px;
  }
  @include tab-sm() {
    display: flex;
    gap: 45px;
    img{
      margin: 0;
      max-width: 600px;
    }
    h2{
      font-size: 50px;
      line-height: 57px;
      font-weight: 500;
    }
    p{
      font-size: 24px;
      line-height: 34px;
    }
    .arshi{
      font-size: 17px;
      line-height: 24px;
    }
  }
}