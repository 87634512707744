@import "../../assets/scss/index.scss";

.container .container-fluid header .header-nav-area #nav_container {
    display: flex;
    letter-spacing: 10px;
    padding: 40px;
    margin: 30px;
}

.Talk_us {
    display: block;
    @include tab-sm() {
        flex-direction: row;
    }

    .Talk_us_text {
        .talk_us_info_p {
            width: 104px;
            height: 37px;
            margin-left: 10px;
            text-align: justify;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: #000000;
        }

        .hyper_line {
            margin-bottom: 70px;
        }

        .talk_us_info1 {
            width: 338px;
            height: 218px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 57.3477px;
            line-height: 67px;
            letter-spacing: -0.03em;
            color: #343434;

            @include tab-sm() {
                width: 528px;
                height: 118px;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 57.3477px;
                line-height: 67px;
                letter-spacing: -0.03em;
                color: #343434;
            }
        }

        p {
            width: 345px;
            height: 99px;
            left: 311px;
            top: 447px;

            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 20.2819px;
            line-height: 33px;
            color: #636363;

            @include tab-sm() {
                width: 505px;
                height: 99px;
                left: 311px;
                top: 447px;

                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 20.2819px;
                line-height: 33px;
                color: #636363;
            }
        }
    }

    .Talk_us_1 {
        .Talk_us_text1 {
            width: 188px;
            height: 94px;
            left: 1109px;
            top: 308px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 21.7px;
            line-height: 33px;
            color: #bdbdbd;
        }
    }

    .Talk_us_talk {
        // margin-left: 120px;
        margin-top: 50px;

        @include tab-sm() {
            margin-left: 200px;
            margin-top: 40px;
        }

        .span {
            max-width: 100%;
            font-size: 18px;
            font-weight: 100;
            color: #000000;
        }

        .Talk_us_text {
            width: 148px;
            height: 94px;
            left: 1109px;
            top: 308px;
            font-family: "Poppins";
            font-style: bold;
            font-weight: 700;
            font-size: 21.7px;
            line-height: 33px;

            color: #000000;
        }
    }

    .Talk_us_talk_new {
        margin-left: 120px;
        margin-top: 50px;

        @include tab-sm() {
            margin-left: 70px;
            margin-top: 40px;
        }

        .span {
            max-width: 100%;
            font-size: 18px;
            font-weight: 100;
            color: #000000;
        }

        .Talk_us_text {
            width: 190px;
            height: 94px;
            left: 1109px;
            top: 308px;
            font-family: "Poppins";
            font-style: bold;
            font-weight: 900;
            font-size: 21.7px;
            line-height: 33px;

            color: #000000;
        }
    }

    .Talk_us_talk1 {
        // margin-left: 120px;
        // margin-top: 50px;

        @include tab-sm() {
            margin-left: 50px;
            margin-top: 40px;
        }

        .span {
            font-size: 18px;
            font-weight: 100;
            color: #bdbdbd;
        }

        .Talk_us_text {
            width: 173px;
            height: 14px;
            left: 1334px;
            top: 308px;

            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 21.7px;
            line-height: 33px;

            color: #bdbdbd;
        }
    }

    .Talk_us_talk1_new {
        margin-left: 120px;
        margin-top: 50px;

        @include tab-sm() {
            margin-left: 200px;
            margin-top: 40px;
        }

        .span {
            font-size: 18px;
            font-weight: 100;
            color: #bdbdbd;
        }

        .Talk_us_text {
            width: 130px;
            height: 14px;
            left: 1334px;
            top: 308px;

            font-family: "Poppins";
            font-style: normal;
            font-weight: 900;
            font-size: 21.7px;
            line-height: 33px;

            color: #bdbdbd;
        }
    }

    .Talk_us_talk1_click {
        margin-left: 120px;
        margin-top: 50px;

        .span {
            font-size: 18px;
            font-weight: 100;
        }

        .Talk_us_text {
            width: 223px;
            height: 14px;
            left: 1334px;
            top: 308px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 21.7px;
            line-height: 33px;
            color: #000000;
        }
    }

    .Talk_us_2 {
        .Talk_us_text1 {
            width: 273px;
            height: 94px;
            left: 1334px;
            top: 308px;

            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 21.7px;
            line-height: 33px;

            color: #bdbdbd;
        }
    }
}

.Talk {
    width: 305px;
    height: 89px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 46.9904px;
    line-height: 79px;
    text-align: center;
    color: #000000;
    margin-top: 110px;

    @include tab-sm() {
        width: 505px;
        height: 109px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 46.9904px;
        line-height: 109px;
        text-align: center;
        color: #000000;
        margin-top: 30px;
    }
}

.Talk1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 49px;
    margin-top: 30px;
    margin-bottom: 25px;
    color: #000000;
    @include tab-sm() {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 46px;
        margin-top: 60px;
        line-height: 59px;
        color: #000000;
    }
}

.Talk_through_contact {
    // display: flex;
    // flex-direction: column;
    // gap: 16px;
    // // align-items: center;
    // margin-bottom: 30px;
    // @include tab-sm() {
    //     flex-direction: row;
    //     gap: 30px;
    //     justify-content: space-between;
    // }

    .Talk_throughWrap {
        @include tab-sm() {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            align-items: flex-start;
        }
    }

    .Talk_through {
        display: flex;
        gap: 16px;
        margin-top: 50px;
        margin-bottom: 20px;
        align-items: center;
        @include tab-sm() {
            flex-direction: row;
            gap: 30px;
        }

        .Talk_through_text {
            img {
                width: 75px;
                height: 75px;
                @include tab-sm() {
                    width: 85px;
                    height: 85px;
                }
            }
        }

        .Talk_through_text1 {
            width: 100%;
            // height: 110px;
            left: 427.36px;
            top: 708.09px;

            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 17.4538px;
            line-height: 27px;
            color: #000000;
        }

        .Talk_through_text2 {
            width: 461px;
            height: 98px;
            left: 914px;
            top: 1603.41px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 19.9937px;
            line-height: 32px;
            color: #636363;
        }
    }

    .Talk_through1 {
        display: flex;
        gap: 16px;

        @include tab-sm() {
            flex-direction: row;
            gap: 40px;
        }

        .Talk_through_text1 {
            img {
                width: 85.43px;
                height: 85.43px;
                left: 311px;
                top: 730.53px;
            }
        }

        .Talk_through_text_1 {
            width: 85px;
            height: 110px;
            left: 427.36px;
            top: 708.09px;

            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 17.4538px;
            line-height: 27px;
            color: #000000;
        }

        .Talk_through_text_2 {
            width: 161px;
            height: 83px;
            left: 512px;
            top: 864px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 17.4538px;
            line-height: 27px;
            color: #000000;
        }
    }

    .Talk_through2 {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include tab-sm() {
            flex-direction: row;
            gap: 40px;
        }

        .Talk_through_text1 {
            img {
                width: 85.43px;
                height: 85.43px;
                left: 311px;
                top: 730.53px;
            }
        }

        .Talk_through_text_1 {
            width: 199px;
            height: 83px;
            left: 427.36px;
            top: 997.16px;

            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 17.4538px;
            line-height: 27px;
            /* or 157% */

            color: #000000;
        }
    }

    .text {
        width: 245px;
        height: 28px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 27.4538px;
        line-height: 27px;
        margin-top: 30px;

        color: #000000;

        @include tab-sm() {
            width: 145px;
            height: 28px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 17.4538px;
            line-height: 27px;
            margin-top: 30px;
            margin-left: 200px;
            color: #000000;
        }
    }

    .text_img {
        position: absolute;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 39.9138px;
        line-height: 52px;
        /* identical to box height, or 129% */

        letter-spacing: -0.04em;
        text-transform: uppercase;

        color: #cdcdcd;

        width: 477px;
        height: 312px;

        text-align: center;
        padding-top: 120px;
        background-image: url(../../assets/img/Rec.png);

        margin-left: 200px;
    }
}

iframe {
    width: 100%;
    height: 150px;
    margin-top: 30px;
    border: none;

    @include tab-sm() {
        width: 600px;
        height: 300px;
        border: none;
    }
}

.contact_form {
    margin-bottom: 20px;
    max-width: 750px;

    @include tab-sm() {
        flex-direction: row;
        gap: 40px;
        display: flex;
    }

    .form {
        width: 100%;
        margin-bottom: 20px;
        @include tab-sm() {
            margin-bottom: 0;
        }
        label {
            display: block;
            font-size: 16px;
            line-height: 22px;
            color: #636363;
            margin-bottom: 10px;
            @include tab-sm() {
                font-size: 20px;
                line-height: 34px;
                margin-bottom: 15px;
            }
        }
        @include tab-sm() {
            width: calc(42% - 10px);
        }
    }

    .formArea {
        width: 100%;
        margin-top: 25px;
        label {
            display: block;
            font-size: 16px;
            line-height: 22px;
            color: #636363;
            margin-bottom: 10px;
            @include tab-sm() {
                font-size: 20px;
                line-height: 34px;
                margin-bottom: 15px;
            }
        }
    }

    .input {
        font-family: "Poppins";
        width: 100%;
        height: 51px;
        border: none;
        padding: 10px 20px;
        background: #f0f0f0;
        border-radius: 5px;
        font-size: 16px;
        color: #636363;
    }
}

.talk_project {
    width: 391px;
    height: 159px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 40.9px;
    line-height: 69px;
    text-align: left;
    color: #000000;

    @include tab-sm() {
        width: 591px;
        height: 109px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 46.9904px;
        line-height: 109px;
        text-align: center;
        color: #000000;
    }
}

.textarea1 {
    font-family: "Poppins";
    width: 100%;
    height: 150px;
    font-size: 16px;
    background: #f0f0f0;
    border-radius: 5px;
    border: none;
    padding: 20px 20px;
    resize: none;
    box-shadow: none;
    outline: none;
    font-size: 16px;
    color: #636363;
    @include tab-sm() {
        height: 150px;
        font-size: 16px;
        background: #f0f0f0;
        border-radius: 5px;
    }
}

.text {
    width: 371px;
    height: 96px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20.2819px;
    line-height: 33px;
    color: #636363;

    @include tab-sm() {
        width: 531px;
        height: 66px;
        left: 315px;
        top: 1768px;

        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 20.2819px;
        line-height: 33px;
        /* or 162% */

        color: #636363;
    }
}

.textarea {
    width: 351px;
    height: 150px;
    margin-top: 20px;
    font-size: 16.1403px;
    background: #f0f0f0;
    border-radius: 5px;
    border: none;
    padding-left: 30px;
    padding-top: 30px;
    resize: none;
    outline: 0;

    @include tab-sm() {
        width: 751px;
        height: 309px;
        margin-top: 40px;
        font-size: 16.1403px;
        background: #f0f0f0;
        border-radius: 5px;
        border: none;
        padding-left: 30px;
        padding-top: 30px;
        resize: none;
    }
}

.join_us {
    .Talk {
        width: 349px;
        height: 189px;
        left: 308px;
        top: 612px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 46.9904px;
        line-height: 79px;
        text-align: center;
        color: #000000;
        margin-top: 100px;

        @include tab-sm() {
            width: 569px;
            height: 109px;
            left: 308px;
            top: 612px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 46.9904px;
            line-height: 109px;
            text-align: center;
            color: #000000;
            margin-top: 30px;
        }
    }

    .Talk1 {
        width: 505px;
        height: 109px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 46.9904px;
        line-height: 109px;
        margin-top: 90px;
        text-align: center;
        color: #000000;

        @include tab-sm() {
            width: 505px;
            height: 109px;
            left: 308px;
            top: 612px;

            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 46.9904px;
            line-height: 109px;
            /* identical to box height, or 231% */
            margin-top: 90px;
            text-align: center;

            color: #000000;
        }
    }

    .Talk_through_contact {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include tab-sm() {
            flex-direction: row;
            gap: 40px;
        }

        .Talk_through {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 30px;

            @include tab-sm() {
                flex-direction: row;
                gap: 40px;
            }

            .Talk_through_text {
                img {
                    width: 85.43px;
                    height: 85.43px;
                    left: 311px;
                    top: 730.53px;
                }
            }

            .Talk_through_text1 {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 17.4538px;
                line-height: 27px;
                /* or 157% */

                color: #000000;
            }

            .Talk_through_text2 {
                width: 461px;
                height: 98px;
                left: 914px;
                top: 1603.41px;

                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 19.9937px;
                line-height: 32px;
                /* or 162% */

                color: #636363;
            }
        }

        .Talk_through1 {
            display: flex;
            flex-direction: column;
            gap: 16px;

            @include tab-sm() {
                flex-direction: row;
                gap: 40px;
            }

            .Talk_through_text1 {
                img {
                    width: 85.43px;
                    height: 85.43px;
                    left: 311px;
                    top: 730.53px;
                }
            }

            .Talk_through_text_1 {
                width: 85px;
                height: 110px;
                left: 427.36px;
                top: 708.09px;

                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 17.4538px;
                line-height: 27px;
                /* or 157% */

                color: #000000;
            }

            .Talk_through_text_2 {
                width: 161px;
                height: 83px;
                left: 512px;
                top: 864px;

                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 17.4538px;
                line-height: 27px;
                /* or 157% */

                color: #000000;
            }
        }

        .Talk_through2 {
            display: flex;
            flex-direction: column;
            gap: 16px;

            @include tab-sm() {
                flex-direction: row;
                gap: 40px;
            }

            .Talk_through_text1 {
                img {
                    width: 85.43px;
                    height: 85.43px;
                    left: 311px;
                    top: 730.53px;
                }
            }

            .Talk_through_text_1 {
                width: 199px;
                height: 83px;
                left: 427.36px;
                top: 997.16px;

                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 17.4538px;
                line-height: 27px;
                /* or 157% */

                color: #000000;
            }
        }

        .text {
            width: 145px;
            height: 28px;
            left: 960px;
            top: 728px;

            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 17.4538px;
            line-height: 27px;
            /* identical to box height, or 157% */

            margin-top: 100px;
            margin-left: 200px;
            color: #000000;
        }

        .text_img {
            position: absolute;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 39.9138px;
            line-height: 52px;
            /* identical to box height, or 129% */

            letter-spacing: -0.04em;
            text-transform: uppercase;

            color: #cdcdcd;

            width: 477px;
            height: 312px;

            text-align: center;
            padding-top: 120px;
            background-image: url(../../assets/img/Rec.png);

            margin-left: 200px;
        }
    }

    .contact_form {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 50px;

        @include tab-sm() {
            flex-direction: row;
            gap: 40px;
        }

        .form {
            margin-top: 20px;
        }

        .firstename {
            width: 147px;

            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 22.6985px;
            line-height: 34px;
            /* identical to box height, or 416% */

            color: #000000;

            opacity: 0.87;
        }

        .lastname {
            width: 147px;

            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 22.6985px;
            line-height: 34px;
            /* identical to box height, or 416% */

            color: #000000;

            opacity: 0.87;

            @include tab-sm() {
                width: 147px;

                left: 752.25px;
                top: 1433.57px;
                margin-left: 50px;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 22.6985px;
                line-height: 34px;
                /* identical to box height, or 416% */

                color: #000000;

                opacity: 0.87;
            }
        }

        .input {
            width: 311px;
            height: 51px;
            left: 313px;
            top: 1380px;
            border: none;
            padding: 30px;
            background: #f0f0f0;
            border-radius: 5px;
            font-size: 18px;
        }
    }

    .contact_form1 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 120px;

        @include tab-sm() {
            flex-direction: row;
            gap: 40px;
        }

        .form {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;
        }

        .firstename {
            width: 147px;

            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 22.6985px;
            line-height: 34px;
            /* identical to box height, or 416% */

            color: #000000;

            opacity: 0.87;
        }

        .lastname {
            height: 95px;

            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 22.6985px;
            line-height: 34px;
            /* identical to box height, or 416% */

            color: #000000;

            opacity: 0.87;

            @include tab-sm() {
                width: 282px;
                height: 95px;
                left: 752.25px;
                top: 1433.57px;
                margin-left: 50px;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 22.6985px;
                line-height: 34px;
                /* identical to box height, or 416% */

                color: #000000;

                opacity: 0.87;
            }
        }

        .input {
            width: 311px;
            height: 51px;
            left: 313px;
            top: 1380px;
            border: none;
            padding: 30px;
            background: #f0f0f0;
            border-radius: 5px;
            font-size: 18px;
        }
    }

    .talk1 {
        width: 384px;
        height: 129px;

        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 46.9904px;
        line-height: 59px;
        /* identical to box height, or 231% */

        color: #000000;

        @include tab-sm() {
            width: 594px;
            height: 109px;

            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 46.9904px;
            line-height: 109px;
            /* identical to box height, or 231% */

            color: #000000;
        }
    }

    .text {
        width: 351px;
        height: 66px;
        left: 315px;
        top: 1768px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 20.2819px;
        line-height: 33px;
        color: #636363;

        @include tab-sm() {
            width: 531px;
            height: 66px;
            left: 315px;
            top: 1768px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 20.2819px;
            line-height: 33px;
            color: #636363;
        }
    }

    .document1 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 50px;

        @include tab-sm() {
            flex-direction: row;
            gap: 40px;
        }

        .document1_p {
            width: 194px;
            height: 95px;
            left: 642px;
            top: 1806px;

            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 14.14px;
            line-height: 94px;
            /* identical to box height, or 667% */

            text-align: center;

            color: #000000;

            opacity: 0.46;
        }
    }

    .textarea {
        width: 351px;
        height: 309px;
        left: 312px;
        top: 1900px;
        margin-top: 60px;
        background: #f0f0f0;
        border-radius: 5px;
        border: none;
        padding-left: 30px;
        padding-top: 30px;
        resize: none;

        @include tab-sm() {
            width: 751px;
            height: 309px;
            left: 312px;
            top: 1900px;
            background: #f0f0f0;
            border-radius: 5px;
            border: none;
            padding-left: 30px;
            padding-top: 30px;
            resize: none;
        }
    }

    textarea:focus {
        outline: none !important;
        border-color: #ff2e84;
        box-shadow: 0 0 10px #ff2e84;
    }
}

.scrollTop {
    width: 45px;
    height: 45px;
    position: fixed;
    z-index: 5;
    right: 20px;
    bottom: 75px;
    display: block;
    border: none;
    cursor: pointer;
    text-indent: -9999px;
    background: rgba(255, 255, 255, 0.5) url("../../assets/img/arrow.png") no-repeat center center;
    background-size: 100%;
    transition: all 0.3s ease;
}
@media (min-width: 1024px) {
    .scrollTop {
        right: 20px;
        bottom: 75px;
    }
}
.scrollTop:hover {
    background: rgba(255, 255, 255, 0.1) url("../../assets/img/arrow.png") no-repeat center center;
    background-size: 100%;
}

.document_text {
    width: 422px;
    height: 95px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 16.1403px;
    line-height: 94px;
    text-align: center;
    color: #000000;
    opacity: 0.46;

    @include tab-sm() {
        width: 422px;
        height: 95px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 16.1403px;
        line-height: 94px;
        text-align: center;
        color: #000000;
        opacity: 0.46;
    }
}

.vacancy {
    width: 352px;
    height: 50px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 17.7px;
    line-height: 25px;
    color: #636363;
    opacity: 0.87;
}
