@import "../../assets/scss/index.scss";


.companyInfo {
    display: flex;
    flex-direction: column;
    gap: 16px;
    @include tab-sm() {
        flex-direction: row;
        gap: 40px;
    }
    > div {
        max-width: 180px;
        padding-bottom: 18px;
        border-bottom: solid 2px var(--title-color);
        opacity: 0;
        filter: blur(1px);
        &:first-child {
            animation: fade-in 0.6s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }
        &:nth-child(2n) {
            animation: fade-in 0.6s 2.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }
        &:last-child {
            border: none;
            animation: fade-in 0.6s 3.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }
        @include tab-sm() {
            max-width: inherit;
            border: none;
            border-right: solid 2px var(--title-color);
            padding: 0 40px 0 0;
        }
    }
    h2 {
        color: var(--title-color);
        font-weight: 700;
        font-size: 22px;
        line-height: 35px;
        margin-bottom: 0px;
        @include tab-sm() {
            font-size: 25px;
            line-height: 36px;
            margin-bottom: 5px;
        }
    }
    p {
        color: var(--para-color);
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        @include tab-sm() {
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 10px;
        }
    }
}
.companywebsite {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    @include tab-sm() {
        flex-direction: row;
        gap: 40px;
        margin-top: 60px;
        
    }
    .companywebsite_img1 {
        padding-bottom: 10px;
        max-width: 600px;
        video{
            width: 100%;
        }
        @include tab-sm() {
            opacity: 0;
            animation: fade-in 0.6s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }
    }
    .companywebsite_img2 {
        margin-top: 20px;
        max-width: 600px;
        video{
            width: 100%;
        }
        @include tab-sm() {
            margin-top: 150px;
            opacity: 0;
            animation: fade-in 0.6s 2.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }
    }
}

.companywebsite_text {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    margin-top: 30px;
    @include tab-sm() {
        flex-direction: row;
        margin-top: 110px;
    }
    .companywebsite_text_1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 42px;
        letter-spacing: -0.03em;
        color: #343434;
        max-width: 600px;
        @include tab-sm() {
            font-size: 52px;
            line-height: 64px;
        }
        .hyper_line {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    .companywebsite_text_2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #636363;
        margin-top: 15px;
        @include tab-sm() {
            font-size: 20px;
            line-height: 33px;
        }
    }
    .companywebsite_text_3 {
        margin: 20px 0;
        font-weight: 400;
        font-size:18px;
        line-height: 28px;
        color: #636363;
        @include tab-sm() {
            font-size: 20px;
            line-height: 33px;
        }
    }

    .companywebsite_text_4 {
        left: 310px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0px;
        color: #636363;
        @include tab-sm() {
            width: 611px;
            height: 66px;
            font-size: 20.1725px;
            line-height: 33px;
            padding-top: 20px;
        }
    }
}
.ourVideo {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    position: relative;
    video{
        width: 100%;
    }
}
a.video {
    position: relative;
    left: 50%;
    top: 50%;
    margin: -50px 0 0 -50px;
    position: absolute;
    width: 100px;
    height: 100px;
    cursor: pointer;
}
a.video span {
    width: 100%;
    height: 100%;
    background: url("../../assets/img/playicon.png") no-repeat center center;
    background-size: 100%;
    background-position: 50% 50%;
    display: block;
}

.text {
    margin: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size:28px;
    line-height: 40px;
    letter-spacing: -0.03em;
    color: #343434;
    max-width: 960px;
    
    @include tab-sm() {
        margin:120px 0 80px;
        p{
            font-weight: 500;
            font-size: 52px;
            line-height: 64px;
            margin-bottom: 30px;
           
        }
    }
    .text1{
        @include tab-sm() {
            p{
                max-width: 650px;
                color: #636363;
                font-size: 22px;
                line-height: 34px;
            }
        }
    }
}

.textLine {
    margin-top: 20px;
    width: 60px;
    height: 5px;    
    background: #373737;
    @include tab-sm() {
        margin-top: 40px;
    }
}

.researchbuild {
    margin-top: 30px;
    @include tab-sm() {
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-left: 325px;
        margin-top: 10px;
    }
    .researchbuild_1 {
        margin-top: 10px;

    }
    .researchbuild_text_1 {
        
        @include tab-sm() {
            display: flex;
            align-Items: center;
        }
    }
    .img {
        max-width: 70px;
        @include tab-sm() {
            position: relative;
            top: 20px;
        }
    }
    .Researchtext {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 34px;
        letter-spacing: -0.04em;
        color: #000000;
        @include tab-sm() {
            margin-Left: 25px;
            font-size: 28px;
            line-height: 35px;
        }
    }
    .Researchtext_1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14.6722px;
        line-height: 24px;
        margin-left: 0;
        margin-bottom: 30px;
        color: #343434;
        @include tab-sm() {
            margin-left: 97px;
        }
    }

}

.uiuxteam {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    gap: 16px;
    text-align: justify;
    @include tab-sm() {
        flex-direction: row;
        gap: 40px;
    }
    .uiuxteam_metasoft {
        position: relative;
        text-align: justify;
    }

    .uiuximg1 {
        width: 357.34px;
        height: 423.75px;
        background: linear-gradient(rgba(0, 0, 0, .65), rgba(0, 0, 0, .65)), url("../../assets/img/ui-ux\ img1.png");
        background-repeat: no-repeat;
        background-size: cover;
        img {
            width: 357.34px;
            height: 423.75px;
        }
    }

    .uiuximg2 {
        width: 357.34px;
        height: 423.75px;
        background: linear-gradient(rgba(0, 0, 0, .65), rgba(0, 0, 0, .65)), url("../../assets/img/ui-ux\ img2.png");
        background-repeat: no-repeat;
        background-size: cover;
        img {
            width: 357.34px;
            height: 423.75px;
        }
    }

    .uiuximg3 {
        width: 357.34px;
        height: 423.75px;
        background: linear-gradient(rgba(0, 0, 0, .65), rgba(0, 0, 0, .65)), url("../../assets/img/ui-ux\ img3.png");
        background-repeat: no-repeat;
        background-size: cover;
        img {
            width: 357.34px;
            height: 423.75px;
        }
    }
    .uiuxblog {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 31px;
        color: #FF2E84;
        text-align: justify;
        margin-top: 20px;
    }
    .uiuxblogtext {
        text-align: justify;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 17.2067px;
        line-height: 28px;
        text-align: justify;
        margin-top: 10px;
        color: #28141E;
    }

    .uiuxblogtext1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 21px;
        text-align: justify;
        margin-top: 20px;
        text-align: justify;
        color: #636363;
    }



    .textonimage {
        position: absolute;
        margin-left: 30px;
        margin-top: 320px;
        color: #FFFFFF;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 19.5937px;
        line-height: 164%;
        text-align: justify;
        letter-spacing: -0.03em;

    }

    .uiuxteam_metasoft_1 {
        position: relative;
        text-align: justify;
    }

    .textonimage1 {
        position: absolute;
        margin-left: 430px;
        margin-top: 320px;
        color: #FFFFFF;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 19.5937px;
        line-height: 164%;
        text-align: justify;
        letter-spacing: -0.03em;

    }

    .uiuxteam_metasoft_2 {
        position: relative;
        text-align: justify;
    }

    .textonimage2 {
        position: absolute;
        margin-left: 820px;
        margin-top: 320px;
        color: #FFFFFF;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 19.5937px;
        line-height: 164%;
        text-align: justify;
        letter-spacing: -0.03em;

    }

}

.testimonial {
    display: block;
    @include tab-sm() {
        // display: flex;
        // flex-direction: wrap;
        // gap: 16px;
    }
    .text{
        margin-top: 0;
        @include tab-sm() {
            text-align: center;
            margin: 0 auto 40px;
        }
    }

    .heading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 49.9167px;
        letter-spacing: -0.03em;
        color: #343434;
        margin-bottom: 25px;
        margin-top: 45px;
        @include tab-sm() {
            margin-top: 0;
        }
    }

    .text1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: #636363;
        margin-bottom: 20px;
        letter-spacing:0;
        @include tab-sm() {
            font-size: 20px;
            line-height: 28px;
        }
    }

    .testimonialtext {
        display: block;
        // padding-left: 55px;
        position: relative;
        margin-top: 35px;
        overflow: hidden;
        @include tab-sm() {
            margin-top: 0;
        }
        .slider{
            position: relative;
        }
        .testimonialtext_p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            color: #000000;
            display: inline-block;
            @include tab-sm() {
                text-indent: 25px;
                padding: 25px 50px;
                font-size: 22px;
                text-align: center;
                line-height: 33px;
            }
            
        }
        .img {
            display: none;
            @include tab-sm() {
                display: block;
                position: absolute;
                top: 20px;
            }
        }
        .img1 {
            display: none;
            position: absolute;
            bottom: 0;
            right: 0;
            @include tab-sm() {
                display: block;
                bottom: 75px;
                right: 25px;
            }
        }
        .client{
            display: block;
            font-size: 16px;
            line-height: 22px;
            margin-top: 20px;
            color: var(--primary-color);
            span{
                display: block;
            }
            @include tab-sm() {
                text-align: center;
            }
        }
    }
}

.futureplan {
    margin-top: 40px;
    margin-bottom: 30px;
    
    background-color: #EFEFEF;
    padding: 50px 0;
    @include tab-sm() {
        margin-top: 80px;
        margin-bottom: 70px;
        padding: 0 0;
        background-image: url(../../assets/img/Group21.png);
        background-repeat: no-repeat;
        background-size: contain;
        padding-bottom: 120px;
        background-position: center 60px;
    }
    .futuretechnology {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin: 0;
        @include tab-sm() {
            flex-direction: row;
            justify-content: space-between;
        }
        .artificial{
            margin-bottom: 25px;
            max-width: 450px;
        }
        .text {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 50px;
            letter-spacing: -0.03em;
            color: #000000;
            margin-bottom: 0;
            .year {
                color: #FF2E84;
                margin-top: 15px;
                font-size: 52.8323px;
                display: block;
                @include tab-sm() {
                    margin-top:10px;
                    font-size: 80px;
                    display: inline-block;
                }
            }
            .para{
                font-size: 22px;
                line-height: 34px;
                letter-spacing:0;
                color: #343434;
                margin-top: 20px;
                max-width: 640px;
            }
            p{
                font-size: 44px;
                line-height: 60px;
                font-weight: 500;
                @include tab-sm() {
                    font-size: 64px;
                    line-height: 80px;
                }
            }
            @include tab-sm() {
                font-size: 52px;
                line-height: 80px;
                max-width: 660px;
                width: 100%;
            }
        }


        .text2 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 46px;
            margin-top: 30px;
            letter-spacing: -0.03em;
            color: #000000;
            margin-bottom: 5px;
            @include tab-sm() {
                margin-top: 190px;
                width: 500px;
                height: 72px;
                font-size: 50px;
                line-height: 76px;
            }
        }

        .text3 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 21.13px;
            line-height: 36px;
            color: #343434;
        }
        @include tab-sm() {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 21.13px;
            
            line-height: 36px;
            color: #343434;
        }
        
    }

    .virtualreality {
    
        @include tab-sm() {
            margin-left: 700px;
            max-width: 390px;
        }
        .virtualrealitytext {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 46px;
            letter-spacing: -0.03em;
      
           
            color: #000000;
            @include tab-sm() {
                font-size:50px;
                line-height:66px;
               width: 450px;
            }
        }
        .virtualrealitytext1 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 21.13px;
            
            line-height: 36px;
            color: #343434;
            margin-bottom: 20px;
            @include tab-sm() {
                width: 450px;
            }
        }
    }
    .internetofthings {
        margin-left: 0;
        
        @include tab-sm() {
            margin-left: 300px;
            margin-top: 0;
           
        }
        .internetofthingstext {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 46px;
            letter-spacing: -0.03em;
            color: #000000;
            @include tab-sm() {
                font-size:50px;
                line-height: 76px;
                width: 600px;
            }
        }
        .internetofthingstext1 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 21.13px;
            line-height: 36px;
           
            color: #343434;
            margin-bottom: 20px;
         
        }
        @include tab-sm() {
            width: 539px;
        }
    }
    .automation {
        margin: 0;
        @include tab-sm() {
            margin-top: 40px;
            margin-left: 440px;
         

        }
        .automationtext {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 46px;
            letter-spacing: -0.03em;
            color: #000000;
            @include tab-sm() {
                font-size:50px;
                line-height: 76px;
            }
        }
        .automationtext1 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 21.13px;
            line-height: 36px;
            color: #343434;
           
        }
        @include tab-sm() {
            width: 500px;
        }
    }
}


.scrollTop {
    width: 45px;
    height: 45px;
    position: fixed;
    z-index: 5;
    right: 20px;
    bottom: 75px;
    display: block;
    border: none;
    cursor: pointer;
    text-indent: -9999px;
    background: rgba(255, 255, 255, 0.5) url("../../assets/img/arrow.png") no-repeat center center;
    background-size: 100%;
    transition: all 0.3s ease;
  }
  @media (min-width: 1024px) {
    .scrollTop {
      right: 20px;
      bottom: 75px;
    }
  }
  .scrollTop:hover {
    background: rgba(255, 255, 255, 0.1) url("../../assets/img/arrow.png") no-repeat center center;
    background-size: 100%;
  }
  