.page_info_head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 33px;
    color: #636363;
    opacity: 0;
    filter: blur(2px);
    &:first-child {
        animation: fade-in 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }
}