
// Buttons
.btn{
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding:0 30px;
  font-size: 16px;
  height: 50px;
  text-decoration: none;
  position: relative;
  border: none;
  font-weight: 400;
  font-family: $primary-font;
  color: var(--title-color);
  border: solid 2px var(--primary-color);
  @include transition(all 0.3s ease);
  @include border-radius(0);
  @include tab-sm-max() { 
      font-size: 14px;
      height: 50px;
      padding:0 20px;
  }
  &.btn-small{
    height:30px;
    font-size: 16px;
    padding:0 10px;
    overflow: hidden;
    // margin-top:11px;
    &::before{
      width: 0%;
      content: "";
      position: absolute;
      left: -15px;
      top: -15px;
      background: var(--primary-color);
      height: 60px;
      z-index: -1;
      @include transform(rotate(20deg));
      @include transition(all 0.3s ease);
    }
    &:hover{
      color: var(--white);
      &:before{
        width: 150%;
      }
    }
  }
}
.btn-primary{
  background: var(--primary-color );
  color: var(--white);
  &:hover{
    color: var(--primary-color );
    background:transparent;
  }
}
.btn-secondary{
  background: transparent;
  color: var(--white);
  border:solid 1px var(--white);
  &:hover{
    background:rgba(255,255,255,0.2);
    color: var(--white );
  }
}
.btn-territory{
  background: transparent;
  color: var(--white);
  border:solid 2px var(--primary-color );
  &:hover{
    background:var(--primary-color );
    color: var(--white );
  }
}
.btn-submit{
  height:66px;
  background:  var(--primary-color); 
  padding:0 35px;
  color: var(--white);
  font-size: 20px;
  font-weight: 700;
  @include border-radius(2px);
  @include  desktop-sm() {
    height:86px;
    font-size: 26px;
  }
  &:hover{
    background:var(--primary-color );
    color: var(--white );
  }
}
.link-btn{
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding:0 0 5px 0;
  margin-bottom: 20px;
  text-transform:uppercase;
  font-size: 16px;
  text-decoration: none;
  position: relative;
  border: none;
  font-weight: 600;
  font-family: $primary-font;
  color: var(--primary-color);
  background: transparent;
  border: none;
  @include transition(all 0.3s ease);
  &:after{
    width: 0%;
    height:1px;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    background: var(--primary-color);
    @include transition(all 0.3s ease);
  }
  &:hover{
    &:after{
      width: 100%;
    }
  }
}

.btn2{
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
text-align: center;
margin-left: 550px;
  font-size: 16px;
  height: 31.93px;
  text-decoration: none;
  position: relative;
  box-sizing: border-box;
width: 100px;
margin-bottom: 60px;
 color: #000;
  font-weight: 400;
  font-family: $primary-font;
  
  border: solid 2px var(--primary-color);
  @include transition(all 0.3s ease);
  @include border-radius(0);
  @include tab-sm-max() { 
      font-size: 14px;
      height: 50px;
      padding:0 20px;
  }
  .btn-primary1{
    background: var(--white );
    color: var(--black);
    &:hover{
      color: var(--white );
      background: var(--primary-color);
    }
  }
}
.btn3 {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
margin-top: 30px;
  font-size: 16px;
  height: 31.93px;
  text-decoration: none;
  position: relative;
  box-sizing: border-box;
  width: 100px;
  margin-bottom: 60px;
  color: #000;
  font-weight: 400;
  font-family: $primary-font;

  border: solid 2px var(--primary-color);
  @include transition(all 0.3s ease);
  @include border-radius(0);

  @include tab-sm-max() {
    font-size: 14px;
    height: 50px;
    padding: 0 20px;
  }

  .btn-primary1 {
    background: var(--white);
    color: var(--black);

    &:hover {
      color: var(--white);
      background: var(--primary-color);
    }
  }
}

.btn4 {
  margin-top: 20px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 110px;
  font-size: 16px;
  height: 31.93px;
  text-decoration: none;
  position: relative;
  box-sizing: border-box;
  width: 100px;
  
  margin-bottom: 60px;
  color: #000;
  font-weight: 400;
  font-family: $primary-font;

  border: solid 2px var(--primary-color);
  @include transition(all 0.3s ease);
  @include border-radius(0);

  @include tab-sm-max() {
    font-size: 14px;
    height: 50px;
    padding: 0 20px;
  }

  .btn-primary1 {
    background: var(--white);
    color: var(--black);

    &:hover {
      color: var(--white);
      background: var(--primary-color);
    }
  }
}
.btn5 {
  margin-top: 20px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 300;
  font-size:18px;
  text-decoration: none;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  margin-top: 20px; 
  margin-bottom: 30px;
  color: #fff;
  font-weight: 300;
  font-family: $primary-font;
  background-color:  #FF2E84;;
  @include transition(all 0.3s ease);
  @include border-radius(14);
  @include tab-sm() {
    height: 70px;
    max-width: 300px;
    font-size: 22px;
    margin-bottom: 30px;
    margin-top: 30px; 
    border-radius: 14px;
  }


}
.btn6 {
  margin-top: 20px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
 
  font-weight: 300;
  font-size: 22.6985px;

  text-decoration: none;
  position: relative;
  box-sizing: border-box;
  width: 241px;
  height: 51px;

  font-weight: 300;
font-size: 16.1403px;
line-height: 94px;
  margin-bottom: 60px;
  text-align: center;
  text-decoration-line: underline;
  
  color: #000000;

  font-family: $primary-font;
  border: 1px solid #FF2E84;
  border-radius: 5px;
 
  @include transition(all 0.3s ease);
  @include border-radius(14);

  @include tab-sm-max() {
    font-size: 14px;
    height: 50px;
    padding: 0 20px;
  }

  .btn-primary1 {
    background: var(--primary-color);
    color: var(--black);

    &:hover {
      color: var(--white);
      background: var(--black);
    }
  }
}