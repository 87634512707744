@import "../../assets/scss/index.scss";

.project_info {
    margin-bottom: 30px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */
    color: #343434;

    @include tab-sm() {
        margin-bottom: 30px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        color: #343434;
    }

    .project_info1 {


        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 36px;
        letter-spacing: -0.03em;
        color: #343434;

        @include tab-sm() {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 54.8254px;
            line-height: 64px;
            width: 1171px;
            color: #343434;
            margin-top: 10px;
        }
    }

    p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #636363;

        @include tab-sm() {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: #343434;
        }
    }

}

.Project_img {
    img {
        max-width: 100%;

    }
}

@include tab-sm() {
    .Project_img {
        text-align: center;
        padding: 50px 0 0;
        img {
            max-width: 100%;
        }
    }
}

.hyper_line {
    margin-top: 35px;
    margin-bottom: 40px;
}

.website_details {
    display: flex;
    gap: 16px;
    margin: 15px 0 25px 0;
    justify-content: center;
    text-align: center;
    font-size: 14px;

    @include tab-sm() {
        flex-direction: row;
        gap: 80px;
    }

    .website_img {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #646464;

        @include tab-md() {
            display: block;
            align-items: center;
        }

        a {
            display: flex;
            align-items: center;
            gap: 10px;
            color: #646464;

            @include tab-md() {
                display: block;
                align-items: center;
            }
        }

        img {
            width: 30px;
            height: 30px;

            @include tab-md() {
                width: 40px;
                height: 40px;
            }
        }

        .p {
            width: 85px;
            height: 21px;
            left: 848px;
            top: 1290px;
            text-align: center;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            /* identical to box height */


            color: #646464;
        }
    }
}
.projectimglink{
    width: 950px;
    object-fit: contain;
}
.clients {
    margin-top: 50px;
    @include tab-md() {
        margin: 100px 0;
    }
    .cleints_text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 38px;
        letter-spacing: -0.01em;
        color: #000000;
        @include tab-md() {
            font-weight: 600;
            font-size: 31.784px;
            line-height: 48px;
            text-align: center;
            margin-bottom: 10px;
        }
    }

    p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        color: #636363;
        @include tab-md() {
            text-align: center;
            font-size: 18px;
            line-height: 29px;
        }
    }

}



.programming_language {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 20px;
    justify-content: center;
    text-align: center;

    @include tab-sm() {
        flex-direction: row;
        gap: 50px;
        margin-top: 50px;
    }

    .programming_language_img {
        width: 150px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;

        img {
            max-width: 100%;
            height: 50px;
            object-fit: contain;
        }

        @include tab-sm() {
            width: 208px;
            height: 70px;
            margin: 10px;
            img {
                height: auto;
            }
        }

        img {
            margin: 0;
        }
    }
}

.programming_language_text {

    p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        color: #636363;
        @include tab-md() {
            text-align: center;
            font-size: 18px;
            line-height: 29px;
        }
    }

    .programming_language_text_p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 38px;
        letter-spacing: -0.01em;
        color: #000000;
        @include tab-md() {
            font-weight: 600;
            font-size: 31.784px;
            line-height: 48px;
            text-align: center;
            margin-bottom: 10px;
        }
    }
}

.btncls {
    text-align: center;
    margin-bottom: 50px;
}


.scrollTop {
    width: 45px;
    height: 45px;
    position: fixed;
    z-index: 5;
    right: 20px;
    bottom: 75px;
    display: block;
    border: none;
    cursor: pointer;
    text-indent: -9999px;
    background: rgba(255, 255, 255, 0.5) url("../../assets/img/arrow.png") no-repeat center center;
    background-size: 100%;
    transition: all 0.3s ease;
  }
  @media (min-width: 1024px) {
    .scrollTop {
      right: 20px;
      bottom: 75px;
    }
  }
  .scrollTop:hover {
    background: rgba(255, 255, 255, 0.1) url("../../assets/img/arrow.png") no-repeat center center;
    background-size: 100%;
  }
  