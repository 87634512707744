input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  font-family: $primary-font;
  background: var(--white);
}

input, button, input:focus, button:focus{
  outline: none;
}

*, body {
  margin: 0;
  padding: 0;
  @include box-sizing(border-box);
  text-decoration: none;
  color: #000000;
}

input, button, input:focus, button:focus{
  outline: none;
}

.text-center{
  text-align: center;
}

.img-fluid {
  max-width: 100%;
}
.w-100{
  width: 100%;
}

// Margin

.m-auto{
  margin: 0 auto;
}
.mt-30{
  margin-top: 30px;
}
.mb-30{
  margin-bottom: 30px;
}
.mb-50{
  margin-bottom: 50px;
}

// Padding

.pt-50{
  padding-top: 50px;
}
.pt-0{
  padding-top: 0 !important;
}

// Layout

.container {
  padding: 0 20px;
  max-width: 1400px;
  margin: 0 auto; 
  width: 100%;
  @include tab-md() {
    padding: 0 15px;
    max-width: 1200px;
  }
  @include desktop-lg() {
    max-width: 1300px;
  }
}


section{
  padding: 80px 0;
  @include tab-md() {
    padding: 90px 0;
  }
  @include desktop-lg() {
    padding: 100px 0;
  }
}
