// Fonts
$primary-font           :  'Poppins', sans-serif;

// Colors
:root {
    --primary-color     : #FF2E84;
    --title-color       : #343434;
    --sub-title-color   : #28141E;
    --para-color        : #636363;
    --white             : #ffffff;
    --black             : #000000;
    --lightgray         : #EFEFEF;
    --gray              : #BDBDBD;
}

