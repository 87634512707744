
/* Slick Slider */

.slick-prev, .slick-next {
    position: absolute;
    top: 135%;
    font-size: 1.8rem;
  }
  
  .slick-prev {
    left: 0;
  }
  
  .slick-next {
    right: 0;
  }
  
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
        user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  
  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  
  .slick-list:focus {
    outline: none;
  }
  
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  
  .slick-slider .slick-track,
  .slick-slider .slick-list {
      -webkit-transform: translate3d(0, 0, 0);
         -moz-transform: translate3d(0, 0, 0);
          -ms-transform: translate3d(0, 0, 0);
           -o-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
  }
  
  .slick-track {
      position: relative;
      top: 0;
      left: 0;
      display: block;
  }
  
  .slick-track:before,
  .slick-track:after {
      display: table;
      content: '';
  }
  
  .slick-track:after {
      clear: both;
  }
  
  .slick-loading .slick-track {
      visibility: hidden;
  }
  
  .slick-slide {
      display: none;
      float: left;
      height: 100%;
      min-height: 1px;
  }
  
  [dir='rtl'] .slick-slide {
      float: right;
  }
  
  .slick-slide img {
      display: block;
      margin-left: 10px;
  }
  
  .slick-slide.slick-loading img {
      display: none;
      margin: 0 25px;
  }
  
  .slick-slide.dragging img {
      pointer-events: none;
  }
  .slick-initialized .slick-slide {
      display: block;
  }
  .slick-loading .slick-slide {
      visibility: hidden;
  }
  .slick-vertical .slick-slide {
      display: block;
      height: auto;
      border: 1px solid transparent;
  }
  
  .slick-arrow.slick-hidden {
      display: none;
  }
  
  .slide {
      transition: filter .4s;
      margin: 0px 40px;
  }
  
  .fas {
      color: #96bd0b;
  }
  .slick-prev {
      left: 0;
  }
  .slick-prev, .slick-next {
      position: absolute;
      top: 35%;
      font-size: 1.8rem;
  }
  
  .section {
    max-width: 1200px;
    margin: 0 auto;
  }