.form-filed{
    width: 100%;
    margin-bottom: 30px;
    .form-control{
        width: 100%;
        height:62px;
        padding:15px 0;
        border: none;
        background:transparent;
        font-size: 22px;
        font-weight: 300;
        outline: none;
        border-bottom: solid 1px var(--primary-color );
        @include border-radius(0);
        @include  desktop-sm() {
            font-size: 27px;
            height:72px;
        }
    }
    textarea.form-control{
        height:150px;
        resize: none;
    }
}