@import "../../assets/scss/index.scss";

.project_info {

    .project_info_p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #343434;
    }

    .hyper_line {
        margin-top: 50px;
        margin-bottom: 60px;
    }

    .project_info1 {
        width: 378px;
        height: 1007px;
        left: 311px;
        top: 263.91px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 53.6325px;
        line-height: 52px;
        /* or 116% */

        letter-spacing: -0.03em;

        color: #343434;

        @include tab-sm() {
            width: 1126px;
            height: 297px;
            left: 311px;
            top: 263.91px;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 53.6325px;
            line-height: 62px;
            /* or 116% */

            letter-spacing: -0.03em;

            color: #343434;

        }
    }

    p {

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20.2819px;
        line-height: 33px;
        color: #636363;

        @include tab-sm() {

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 20.2819px;
            line-height: 33px;
            color: #636363;

        }

    }

}

.caseBg {

    padding: 50px 0 10px;
    // background: #000000 url("../../assets/images/casestudy_bg.jpg") no-repeat left bottom;
    background-size: 100%;
    text-align: center;

    margin-top: 50px;

    @include desktop-sm() {
        padding: 50px 0;
        // background-size:cover;
        // background-attachment: fixed;

    }

    .process {
        

        .process_discover {
            display: flex;
            flex-direction: column;

            @include tab-sm() {
                flex-direction: row;
                gap: 40px;
                padding: 20px 0;
            }

            .process_descover_text {
                text-align: left;
                margin-top: 50px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 26px;
                line-height: 36px;
                color: #FFFFFF;

                @include tab-sm() {
                    margin-top: 50px;
                    font-size: 39px;
                    line-height: 76px;
                    width: 50%;
                }

            }
        }

        .process_descover_01 {
            margin-top: 30px;
            margin-bottom: 30px;

            @include tab-sm() {
                margin-left: 0;
                margin-top: 50px;
                width: 50%;
            }

            .process_descover_1_number {
                display: flex;
                gap: 16px;
                margin-bottom: 25px;

                @include tab-sm() {
                    flex-direction: row;
                    gap: 40px;
                }

                .process_descover_1_number_1 {
                    width: 19px;
                    height: 33px;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 19.9937px;
                    line-height: 32px;
                    color: rgba(255, 255, 255, 0.90);
                }
            }

            .process_descover_text2 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
                text-align: left;
                color: rgba(255, 255, 255, 0.90);

                @include tab-sm() {
                    color: #FFFFFF;
                    font-size: 19px;
                    line-height: 30px;
                }
            }

            .process_descover_text2_2 {
                width: 288px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 19.9937px;
                line-height: 32px;
                text-align: justify;
                color: #FFFFFF;

                @include tab-sm() {
                    width: 488px;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 19.9937px;
                    line-height: 32px;
                    text-align: justify;
                }
            }

            .process_descover_text2_3 {
                width: 288px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 19.9937px;
                line-height: 32px;
                text-align: justify;
                color: #FFFFFF;

                @include tab-sm() {
                    width: 488px;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 19.9937px;
                    line-height: 32px;
                    text-align: justify;
                    color: #FFFFFF;
                }
            }
        }
    }

    
}


.scrollTop {
    width: 45px;
    height: 45px;
    position: fixed;
    z-index: 5;
    right: 20px;
    bottom: 75px;
    display: block;
    border: none;
    cursor: pointer;
    text-indent: -9999px;
    background: rgba(255, 255, 255, 0.5) url("../../assets/img/arrow.png") no-repeat center center;
    background-size: 100%;
    transition: all 0.3s ease;
  }
  @media (min-width: 1024px) {
    .scrollTop {
      right: 20px;
      bottom: 75px;
    }
  }
  .scrollTop:hover {
    background: rgba(255, 255, 255, 0.1) url("../../assets/img/arrow.png") no-repeat center center;
    background-size: 100%;
  }
  