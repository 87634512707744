
@import "../../assets/scss/index.scss";

.header{
    padding: 15px 0; 
    border-top: solid 15px var(--primary-color);
    position: sticky;
    top: 0;
    background: var(--white);
    z-index: 5;
    @include tab-md() {
        padding: 25px 0; 
        border-top: solid 20px var(--primary-color);
    }
} 
.headwrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include tab-md() {
    }
    .logo{
        width: 125px;
        img{
            max-width: 100%;
        }
        @include tab-md() {
            width: 135px;
            opacity: 0;
            @include transition(all 0.3s ease);
            animation: fade-in 0.6s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
        }
    }
}
.links{
    display: none;
    @include tab-md() {
        display: flex;
        align-items: center;
        min-width: 400px;
        gap: 20px;
        opacity: 0;
        @include transition(all 0.3s ease);
        animation: fade-in-anim 0.6s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }
    a{
        font-weight: 400;
        font-size: 18px;
        text-decoration: none;
        color: var( --title-color);
        @include transition(all 0.3s ease);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(
            to right,
            var(--primary-color),
           var(--primary-color) 50%,
            var(--title-color) 50%);
        background-size: 200% 100%;
        background-position: -100%;
        position: relative;
        &:before{
            display:block;
            content:'';
            width:0;
            height:1px;
            left:0;
            bottom:-5px;
            z-index:0;
            position:absolute;
            background:var(--primary-color);
            transition: all 0.3s ease-in-out;
        }
        &:hover{
            background-position: 0%;
            &:before{
                width:100%;
            }
        }
        &.active{
            &:before{
                width:100%;
            }
        }
    }
}


.menuwrap{
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: flex-end;
    margin-top: 15px;
    @include tab-md() {
        min-width: 400px;
        margin: 0;
        gap: 30px;
        opacity: 0;
        @include transition(all 0.3s ease);
        animation: fade-in 0.6s 2s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }
}
.menu {
    width: 18px;
    height: 12px;
    position: relative;
    margin: 0 ;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    border: none;
    background: transparent;
}
.menu span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: var(--primary-color);
    border-radius: 3px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}
.menu span:nth-child(1) {
    top: 0px;
}
.menu span:nth-child(2) {
    top: 8px;
}
.menu.open span:nth-child(1) {
    top: 18px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}
.menu.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}
.mobileMenu, .mobileMenuOpen{
    position: fixed;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: var(--white);
    z-index: 44;
    display: flex;
    opacity: 0;
    visibility: hidden;
    flex-direction: column;
    justify-content: space-between;
    top: -100px;
    @include transition(all 0.5s ease);
    .close{
        position: absolute;
        width: 17px;
        height: 17px;
        right: 25px;
        top: 25px;
        cursor: pointer;
        text-indent: -9999px;
        background: transparent;
        border: none;
        background: url('../../assets/img/close-icon.svg') no-repeat center center;
        @include transition(all 0.3s ease);
        @include tab-md() {
            right: 100px;
            top: 80px;
        }
        &:hover{
            @include transform(rotate(180deg));
        }
    }
}
.mobileMenuOpen{
    opacity: 1;
    visibility: visible;
    top: 0;
    display: flex;
}
.menuWrap{
    display: block;
    padding-top: 30px;
    @include tab-md() {
        display: flex;
        padding-top: 100px;
        gap: 50px;
        justify-content: space-between;

    }
    ul{
        list-style: none;
    }
    .mainMenu{
        margin-bottom: 30px;
        @include tab-md() {
            width: 30%;
        }
        a{
            display: block;
            font-size: 24px;
            line-height: 40px;
            text-decoration: none;
            font-weight: 500;
            color: var(--black );
            &.active, &:hover{
                color: var(--primary-color );
            }
            @include tab-md() {
                font-size: 55px;
                line-height:66px;
                margin-bottom: 20px;
            }
        }
    }
    .subMenu{
        display: none;
        text-decoration: none;
        @include tab-md() {
            display: flex;
            justify-content: space-around;
            width: 30%;
        }
        ul{
            list-style: none;
           
            margin: 0;
            li{
                font-weight: 400;
                font-size: 22px;
                
                line-height: 44px;

            }
        }
    }
    .letsTalk{
        margin-bottom: 30px;
        @include tab-md() {
            width: 30%;
        }
        h5{
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            margin-bottom: 10px;
            
        }
        h3{
            font-weight: 500;
            font-size: 38px;
            line-height:43px;
            color: var(--title-color);
            margin-bottom: 30px;
            position: relative;
            @include tab-md() {
                font-size: 57px;
                line-height: 67px;
                margin-bottom: 44px;
            }
            &:after{
                width: 52px;
                height: 5px;
                content: "";
                display: block;
                background: var(--primary-color);
                position: absolute;
                left: 0;
                bottom: -20px;
            }
        }
        p{
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 30px;
            color: var(--para-color);
            @include tab-md() {
                font-size: 20px;
                line-height: 33px;
                margin-bottom: 40px;
            }
        }
        a{
            color: var(--sub-title-color);
            &:hover{
                color: var(--white);
            }
        }
    }
}
.mobMenuWrap{
    @include tab-md() {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.socialmenu{ 
    background: var(--primary-color); 
    padding: 25px 0; 
    @include tab-md() {
        padding: 30px 0; 
    }
    .socialWrap{
        display: flex;
        justify-content: space-between;
        @include tab-md() {
            display: block;
        }
    }
    a{
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 30px;
        color: var(--white);
        text-decoration: none;
        margin: 0;
        @include transition(all 0.3s ease);
        @include tab-md() {
            font-size: 24px;
            line-height: 54px;
            margin-right: 40px;
        }
        &:hover{
            color: var(--sub-title-color);
        }
    }
}
.locations{
    display: none;
    @include tab-md() {
        display: flex;
        font-size: 18.0694px;
        line-height: 29px;
        color: var(--white);
        text-transform: uppercase;
        gap: 40px;
    }
}